import axios from "axios";

const BASE_URL = process.env.REACT_APP_BE_URL;

const uploadBerkasForPengajuan = async (id, formData, token) => {
    try {
        return await axios.post(`${BASE_URL}/berkas/upload/${id}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    } catch (error) {
        throw error;
    }
}

const uploadAnalysisResult = async (id, formData, token) => {
    try {
        return await axios.post(`${BASE_URL}/berkas/upload-analisis/${id}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    } catch (error) {
        throw error;
    }
}

const getPresignedBerkasByObject = async (path, token) => {
    try {
        return await axios.get(`${BASE_URL}/berkas/presigned?path=${path}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    } catch (error) {
        throw error;
    }
}

export {
    uploadBerkasForPengajuan,
    uploadAnalysisResult,
    getPresignedBerkasByObject
}