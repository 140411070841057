import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { IoIosSend } from "react-icons/io";
import { TbDeviceIpadHorizontalPlus } from "react-icons/tb";
import { RxCornerTopLeft, RxCornerBottomRight } from "react-icons/rx";
import { CiLocationOn } from "react-icons/ci";
import { postPengajuan } from "../../../../Services/pengajuan";
import HashLoader from "react-spinners/HashLoader";

const SendDetail = ({
  map,
  isMarker,
  isShape,
  isShowDetail,
  toogleShape,
  toogleMarker,
  polygonCoordinates,
  setPolygonCoordinates,
  markerCoordinates,
  setMarkerCoordinates,
}) => {
  let utmObj = require("utm-latlng");
  const { keycloak } = useKeycloak();
  const utmLib = new utmObj();
  const [tujuan, setTujuan] = useState("");
  const [judul, setJudul] = useState("");
  const [dasarSurat, setDasarSurat] = useState();
  const [waktuPengajuan, setWaktuPengajuan] = useState({
    awal: {
      waktu: "",
      tanggal: "",
    },
    akhir: {
      waktu: "",
      tanggal: "",
    },
  });

  const [cloudCoverLevel, setCloudCoverLevel] = useState("0%");
  const [productType, setProductType] = useState("daytime");
  const [urgensiLevel, setUrgensiLevel] = useState("RENDAH");
  const [dataSubmit, setDataSubmit] = useState({
    tanggal_awal_pengajuan: "",
    tanggal_akhir_pengajuan: "",
    array_of_koordinat: "",
    koordinat_tengah: "",
    waktu_awal: "",
    waktu_akhir: "",
    judul_pengajuan: "",
    alasan_pengajuan: "",
    cloud_cover: "",
    urgensi: "",
    tipe_produk: "",
  });

  // LOCATION COORDINATES
  const [inputType, setInputType] = useState("latlon");
  const [topLeftCoord, setTopLeftCoord] = useState(["", ""]);
  const [bottomRightCoord, setBottomRightCoord] = useState(["", ""]);
  const [isSingleCoordinate, setIsSingleCoordinate] = useState(true);
  const [loadingMsg, SetLoadingMsg] = useState(null);
  // For single coordinate
  const sideLength = 5.477; // Side length of the square in kilometers (+- 60km2), 10 if 100km2; 20 if 400km2
  // UTM
  const [easting, setEasting] = useState(["", ""]);
  const [northing, setNorthing] = useState(["", ""]);
  const [zone, setZone] = useState(["", ""]);
  const [zoneLetter, setZoneLetter] = useState(["", ""]);

  const berkasFormData = new FormData();

  useEffect(() => {
    if (inputType !== "latlon" || !markerCoordinates) return;
    if (markerCoordinates[0][0] !== "" && markerCoordinates[0][1] !== "") {
      calculateCornerByCenterCoord(
        markerCoordinates[0][0],
        markerCoordinates[0][1],
        sideLength
      );

      const utm = utmLib.convertLatLngToUtm(
        parseFloat(markerCoordinates[0][1]),
        parseFloat(markerCoordinates[0][0]),
        0
      );

      setEasting([utm.Easting, easting[1]]);
      setNorthing([utm.Northing, northing[1]]);
      setZone([utm.ZoneNumber, zone[1]]);
      setZoneLetter([utm.ZoneLetter, zoneLetter[1]]);
    }
  }, [markerCoordinates]);

  useEffect(() => {
    if (!polygonCoordinates) return;
    // lon, lat
    const topLeft = [
      `${polygonCoordinates[3][0]}`,
      `${polygonCoordinates[3][1]}`,
    ];
    const bottomRight = [
      `${polygonCoordinates[1][0]}`,
      `${polygonCoordinates[1][1]}`,
    ];

    if (JSON.stringify(topLeft) !== JSON.stringify(topLeftCoord)) {
      setTopLeftCoord(topLeft);
    }
    if (JSON.stringify(bottomRight) !== JSON.stringify(bottomRightCoord)) {
      setBottomRightCoord(bottomRight);
    }
  }, [polygonCoordinates]);

  useEffect(() => {
    handleChangeCoordinates("topLeft");
    if (inputType !== "latlon") return;

    if (topLeftCoord[0] !== "" && topLeftCoord[1] !== "") {
      const utm = utmLib.convertLatLngToUtm(
        parseFloat(topLeftCoord[0]),
        parseFloat(topLeftCoord[1]),
        0
      );

      setEasting((prev) => [utm.Easting, prev[1]]);
      setNorthing((prev) => [utm.Northing, prev[1]]);
      setZone((prev) => [utm.ZoneNumber, prev[1]]);
      setZoneLetter((prev) => [utm.ZoneLetter, prev[1]]);
    }
  }, [topLeftCoord]);

  useEffect(() => {
    handleChangeCoordinates("bottomRight");
    if (inputType !== "latlon") return;

    if (bottomRightCoord[0] !== "" && bottomRightCoord[1] !== "") {
      const utm = utmLib.convertLatLngToUtm(
        parseFloat(bottomRightCoord[0]),
        parseFloat(bottomRightCoord[1]),
        0
      );

      setEasting((prev) => [prev[0], utm.Easting]);
      setNorthing((prev) => [prev[0], utm.Northing]);
      setZone((prev) => [prev[0], utm.ZoneNumber]);
      setZoneLetter((prev) => [prev[0], utm.ZoneLetter]);
    }
  }, [bottomRightCoord]);

  useEffect(() => {
    if (inputType === "latlon") return;
    if (easting[0] !== "" && northing[0] !== "" && zone[0] !== "") {
      const latLon = utmLib.convertUtmToLatLng(
        parseFloat(easting[0]),
        parseFloat(northing[0]),
        parseFloat(zone[0]),
        zoneLetter[0].toUpperCase()
      );
      setTopLeftCoord([latLon.lat.toFixed(6), latLon.lng.toFixed(6)]);
      if (isSingleCoordinate) {
        calculateCornerByCenterCoord(
          `${latLon.lng.toFixed(5)}`,
          `${latLon.lat.toFixed(5)}`,
          sideLength
        );
        setMarkerCoordinates([[latLon.lng.toFixed(6), latLon.lat.toFixed(6)]]);
      }
    }
    if (easting[1] !== "" && northing[1] !== "" && zone[1] !== "") {
      const latLon = utmLib.convertUtmToLatLng(
        parseFloat(easting[1]),
        parseFloat(northing[1]),
        parseFloat(zone[1]),
        zoneLetter[1].toUpperCase()
      );
      setBottomRightCoord([latLon.lat.toFixed(6), latLon.lng.toFixed(6)]);
    }
  }, [easting, northing, zone, zoneLetter]);

  useEffect(() => {
    if (!markerCoordinates) return;

    let centerCoordinates;
    if (polygonCoordinates) {
      centerCoordinates = calculateCenterCoordinates(polygonCoordinates);
    }
    setDataSubmit((prev) => ({
      ...prev,
      tanggal_awal_pengajuan: waktuPengajuan.awal.tanggal,
    }));
    setDataSubmit((prev) => ({
      ...prev,
      tanggal_akhir_pengajuan: waktuPengajuan.akhir.tanggal,
    }));
    setDataSubmit((prev) => ({
      ...prev,
      array_of_koordinat: polygonCoordinates,
    }));
    setDataSubmit((prev) => ({
      ...prev,
      koordinat_tengah: isSingleCoordinate
        ? markerCoordinates[0]
        : centerCoordinates,
    }));
    setDataSubmit((prev) => ({
      ...prev,
      awal_waktu: waktuPengajuan.awal.waktu,
    }));
    setDataSubmit((prev) => ({
      ...prev,
      akhir_waktu: waktuPengajuan.akhir.waktu,
    }));
    setDataSubmit((prev) => ({
      ...prev,
      judul_pengajuan: judul,
    }));
    setDataSubmit((prev) => ({
      ...prev,
      alasan_pengajuan: tujuan,
    }));
    setDataSubmit((prev) => ({
      ...prev,
      cloud_cover: cloudCoverLevel,
    }));
    setDataSubmit((prev) => ({
      ...prev,
      urgensi: urgensiLevel,
    }));
    setDataSubmit((prev) => ({
      ...prev,
      tipe_produk: productType,
    }));
  }, [
    polygonCoordinates,
    markerCoordinates,
    tujuan,
    waktuPengajuan,
    cloudCoverLevel,
    urgensiLevel,
    productType,
  ]);

  const calculateCornerByCenterCoord = (centerLon, centerLat, sideLength) => {
    if (!isSingleCoordinate) {
      return;
    }
    const R = 6371;

    // Calculate half of the side length
    const halfSide = sideLength / 2;

    // Top Left Corner
    const topLeft = [
      (
        parseFloat(centerLon) -
        halfSide * (180 / (Math.PI * R * Math.cos((centerLat * Math.PI) / 180)))
      ).toFixed(6),
      (parseFloat(centerLat) + halfSide * (180 / (Math.PI * R))).toFixed(6),
    ];

    // Bottom Right Corner
    const bottomRight = [
      (
        parseFloat(centerLon) +
        halfSide * (180 / (Math.PI * R * Math.cos((centerLat * Math.PI) / 180)))
      ).toFixed(6),
      (parseFloat(centerLat) - halfSide * (180 / (Math.PI * R))).toFixed(6),
    ];

    setTopLeftCoord(topLeft);
    setBottomRightCoord(bottomRight);
  };

  const calculateCenterCoordinates = (rectCoordinates) => {
    if (rectCoordinates) {
      const lon = Number(
        rectCoordinates[0][0] +
          (rectCoordinates[1][0] - rectCoordinates[0][0]) / 2
      ).toFixed(6);
      const lat = Number(
        rectCoordinates[0][1] +
          (rectCoordinates[2][1] - rectCoordinates[0][1]) / 2
      ).toFixed(6);
      return [lon, lat];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dasarSurat.length > 1) {
      for (const file of dasarSurat) {
        berkasFormData.append("file[]", file);
      }
    } else {
      berkasFormData.append("file", dasarSurat[0]);
    }
    berkasFormData.append(
      "tanggal_awal_pengajuan",
      dataSubmit.tanggal_awal_pengajuan
    );
    berkasFormData.append(
      "tanggal_akhir_pengajuan",
      dataSubmit.tanggal_akhir_pengajuan
    );
    berkasFormData.append(
      "array_of_koordinat",
      JSON.stringify(dataSubmit.array_of_koordinat)
    );
    berkasFormData.append(
      "koordinat_tengah",
      JSON.stringify(dataSubmit.koordinat_tengah)
    );
    berkasFormData.append("awal_waktu", dataSubmit.waktu_awal);
    berkasFormData.append("akhir_waktu", dataSubmit.waktu_akhir);
    berkasFormData.append("judul_pengajuan", dataSubmit.judul_pengajuan);
    berkasFormData.append("alasan_pengajuan", dataSubmit.alasan_pengajuan);
    berkasFormData.append("cloud_cover", dataSubmit.cloud_cover);
    berkasFormData.append("urgensi", dataSubmit.urgensi);
    berkasFormData.append("tipe_produk", dataSubmit.tipe_produk);

    try {
      const loadingArray = ["Loading.", "Loading..", "Loading..."];
      let loadingIndex = 0;

      SetLoadingMsg(loadingArray[loadingIndex]);
      const loadingInterval = setInterval(() => {
        ++loadingIndex;
        if (loadingIndex >= loadingArray.length) {
          loadingIndex = 0;
        }
        SetLoadingMsg(loadingArray[loadingIndex]);
      }, 500);
      await postPengajuan(berkasFormData, keycloak.token);
      clearInterval(loadingInterval);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const enableDrawAOI = () => {
    let pointLayer;
    const aoiBtnElem = document.getElementById("aoi-btn-inactive");
    if (aoiBtnElem) {
      toogleShape("rectangle");
    }
    if (map) {
      map.getLayers().forEach((layer) => {
        if (layer.get("id") === "point-vector") {
          pointLayer = layer;
        }
      });
    }
    map.removeLayer(pointLayer);
  };

  const enableMarker = () => {
    let shapeLayer;
    const markerBtnElem = document.getElementById("marker-btn-inactive");
    if (markerBtnElem) {
      toogleMarker();
    }
    if (map) {
      map.getLayers().forEach((layer) => {
        if (layer.get("id") === "shape-vector") {
          shapeLayer = layer;
        }
      });
    }
    map.removeLayer(shapeLayer);
  };

  const handleChangeTujuan = (e) => {
    setTujuan(e.target.value);
  };

  const handleChangeJudul = (e) => {
    setJudul(e.target.value);
  };

  const handleChangeCoordinates = (position) => {
    if (!polygonCoordinates) {
      return;
    }

    const newArray = [...polygonCoordinates];
    if (position === "topLeft") {
      newArray[3][0] = topLeftCoord[0];
      newArray[3][1] = topLeftCoord[1];
      newArray[0][0] = topLeftCoord[0];
      newArray[2][1] = topLeftCoord[1];
      newArray[4][0] = topLeftCoord[0];
    } else {
      newArray[1] = bottomRightCoord;
      newArray[0][1] = bottomRightCoord[1];
      newArray[2][0] = bottomRightCoord[0];
      newArray[4][1] = bottomRightCoord[1];
    }
    setPolygonCoordinates(newArray);
  };

  const handleChangeWaktu = (e, rentang) => {
    if (rentang === "awal") {
      setWaktuPengajuan((prev) => ({
        ...prev,
        awal: {
          ...prev.awal,
          waktu: e.target.value,
        },
      }));
    } else {
      setWaktuPengajuan((prev) => ({
        ...prev,
        akhir: {
          ...prev.akhir,
          waktu: e.target.value,
        },
      }));
    }
  };

  const handleChangeTanggal = (e, rentang) => {
    if (rentang === "awal") {
      setWaktuPengajuan((prev) => ({
        ...prev,
        awal: {
          ...prev.awal,
          tanggal: e.target.value,
        },
      }));
    } else {
      setWaktuPengajuan((prev) => ({
        ...prev,
        akhir: {
          ...prev.akhir,
          tanggal: e.target.value,
        },
      }));
    }
  };

  const handleChangeProductType = (e) => {
    setProductType(e.target.value);
  };

  const handleChangeCloudCover = (e) => {
    setCloudCoverLevel(e.target.value);
  };

  const handleChangeUrgensiLevel = (e) => {
    setUrgensiLevel(e.target.value);
  };

  const clearCoordinates = () => {
    setMarkerCoordinates([["", ""]]);
    setPolygonCoordinates([
      ["", ""],
      ["", ""],
      ["", ""],
      ["", ""],
      ["", ""],
    ]);
  };

  const handleChangeCoordMode = (e) => {
    if (e.target.value === "doubleCoord") {
      setIsSingleCoordinate(false);
      clearCoordinates();
      if (isMarker) {
        toogleMarker();
      }
    } else {
      setIsSingleCoordinate(true);
      clearCoordinates();
      if (isShape) {
        toogleShape("rectangle");
      }
    }
  };

  const handleChangeInputType = (e) => {
    setInputType(e.target.value);
    if (isSingleCoordinate) {
      if (isMarker) {
        toogleMarker();
      }
    } else {
      if (isShape) {
        toogleShape("rectangle");
      }
    }
  };

  const handleOnFileChange = (e) => {
    const file = e.target.files;
    setDasarSurat(file);
  };

  const latLonComponent = (latVal, lonVal, latSetter, lonSetter) => {
    return (
      <div>
        <div className="flex mb-1">
          <p className="cursor-default text-large my-1 w-2/5">Latitude</p>
          <p className="text-large mb-0 mr-2">:</p>
          <input
            required
            type="text"
            value={latVal}
            onChange={latSetter}
            className="bg-primary-darkBlue focus:outline-none w-3/5 p-1"
          />
        </div>
        <div className="flex">
          <p className="cursor-default text-large my-1 w-2/5">Longitude</p>
          <p className="text-large mb-0 mr-2">:</p>
          <input
            required
            type="text"
            value={lonVal}
            onChange={lonSetter}
            className="bg-primary-darkBlue focus:outline-none w-3/5 p-1"
          />
        </div>
      </div>
    );
  };

  const utmComponent = (idx) => {
    return (
      <div>
        <div className="flex">
          <p className="cursor-default text-large my-1 w-2/5">Zone Number</p>
          <p className="text-large mb-0 mr-2">:</p>
          <input
            required
            type="number"
            placeholder="Zone Number"
            value={zone[idx]}
            onChange={(e) => {
              setZone((prev) => [
                ...prev.slice(0, idx),
                e.target.value,
                ...prev.slice(idx + 1),
              ]);
            }}
            className="w-3/5 bg-primary-darkBlue focus:outline-none p-1"
          />
        </div>
        <div className="flex">
          <p className="cursor-default text-large my-1 w-2/5">Zone Letter</p>
          <p className="text-large mb-0 mr-2">:</p>
          <input
            required
            type="text"
            placeholder="Zone Letter"
            value={zoneLetter[idx]}
            onChange={(e) => {
              setZoneLetter((prev) => [
                ...prev.slice(0, idx),
                e.target.value,
                ...prev.slice(idx + 1),
              ]);
            }}
            className="w-3/5 bg-primary-darkBlue focus:outline-none p-1"
          />
        </div>
        <div className="flex">
          <p className="cursor-default text-large my-1 w-2/5">Easting</p>
          <p className="text-large mb-0 mr-2">:</p>
          <input
            required
            type="number"
            placeholder="Easting"
            value={easting[idx]}
            onChange={(e) => {
              setEasting((prev) => [
                ...prev.slice(0, idx),
                e.target.value,
                ...prev.slice(idx + 1),
              ]);
            }}
            className="w-3/5 bg-primary-darkBlue focus:outline-none p-1"
          />
        </div>
        <div className="flex">
          <p className="cursor-default text-large my-1 w-2/5">Northing</p>
          <p className="text-large mb-0 mr-2">:</p>
          <input
            required
            type="number"
            placeholder="Northing"
            value={northing[idx]}
            onChange={(e) => {
              setNorthing((prev) => [
                ...prev.slice(0, idx),
                e.target.value,
                ...prev.slice(idx + 1),
              ]);
            }}
            className="w-3/5 bg-primary-darkBlue focus:outline-none p-1"
          />
        </div>
      </div>
    );
  };

  return (
    <form action="" onSubmit={(e) => handleSubmit(e)}>
      {/* JUDUL */}
      <div className="mb-4">
        <p className="cursor-default text-xl text-center border border-white p-1 mb-1">
          Judul Pengajuan
        </p>
        <textarea
          required
          name="judul"
          id="judul"
          placeholder="Judul ..."
          disabled={isShowDetail ? false : true}
          className="w-full h-24 text-black resize-none p-1 focus:outline-none"
          onInput={(e) => handleChangeJudul(e)}
        ></textarea>
      </div>
      {/* TIPE PRODUK */}
      <div className="mb-4">
        <p className="cursor-default text-xl text-center border border-white p-1 mb-1">
          Tipe Produk
        </p>
        <div className="flex text-large">
          <p className="cursor-default text-large my-1 w-2/5">Tipe</p>
          <p className="text-large mb-0 mr-2">:</p>
          <select
            name="tipeProduk"
            id="tipeProduk"
            className="w-3/5 rounded-sm bg-primary-darkBlue px-2"
            onChange={(e) => handleChangeProductType(e)}
          >
            <option value="daytime">Daytime</option>
            <option value="area2x1">Area 2x1</option>
            <option value="burst">Burst</option>
            <option value="nighttime">Nighttime</option>
            <option value="2-frame">2-Frame Stereo</option>
            <option value="5-frame">5-Frame Stereo</option>
          </select>
        </div>
      </div>
      {/* CATATAN */}
      <div className="mb-4">
        <p className="cursor-default text-xl text-center border border-white p-1 mb-1">
          Catatan
        </p>
        <textarea
          required
          name="pesan"
          id="pesan"
          placeholder="Pesan ..."
          disabled={isShowDetail ? false : true}
          className="w-full h-48 text-black resize-none p-1 focus:outline-none"
          onInput={(e) => handleChangeTujuan(e)}
        ></textarea>
      </div>
      {/* DOKUMEN */}
      <div className="mb-4">
        <p className="cursor-default text-xl text-center border border-white p-1 mb-1">
          Dokumen
        </p>
        <div className="flex text-large mb-2">
          <p className="cursor-default text-large my-1 w-2/5">Dasar Surat</p>
          <p className="text-large mb-0 mr-2">:</p>
          <input
            type="file"
            accept="application/pdf"
            onChange={(e) => handleOnFileChange(e)}
            multiple
            required
            className="w-3/5"
          />
        </div>
      </div>
      {/* KOORDINAT LOKASI */}
      <div className="mb-4">
        <p className="cursor-default text-xl text-center border border-white p-1 mb-1">
          Koordinat Lokasi
        </p>
        <div className="flex text-large mb-2">
          <p className="cursor-default text-large my-1 w-2/5">Mode</p>
          <p className="text-large mb-0 mr-2">:</p>
          <select
            name="coordMode"
            id="coordMode"
            className="w-3/5 rounded-sm bg-primary-darkBlue px-2"
            onChange={(e) => handleChangeCoordMode(e)}
          >
            <option value="singleCoord">Pin Lokasi</option>
            <option value="doubleCoord">Gambar AOI</option>
          </select>
        </div>
        <div className="flex text-large mb-2">
          <p className="cursor-default text-large my-1 w-2/5">Format</p>
          <p className="text-large mb-0 mr-2">:</p>
          <select
            name="coordFormat"
            id="coordFormat"
            className="w-3/5 rounded-sm bg-primary-darkBlue px-2"
            value={inputType}
            onChange={(e) => handleChangeInputType(e)}
          >
            <option value="latlon">Latitude/Longitude</option>
            <option value="utm">UTM</option>
          </select>
        </div>
        {isSingleCoordinate ? (
          <div>
            {markerCoordinates && (
              <div>
                <div className="mb-2">
                  <div>
                    <div className="flex justify-center">
                      <div className="w-3/4 flex justify-center items-center mb-2 border-b border-white">
                        <p className="cursor-default text-large text-center mb-0 mr-2">
                          Koordinat
                        </p>
                        <CiLocationOn />
                      </div>
                    </div>
                  </div>
                  {inputType === "latlon"
                    ? latLonComponent(
                        markerCoordinates[0][1],
                        markerCoordinates[0][0],
                        (e) =>
                          setMarkerCoordinates([
                            [markerCoordinates[0][0], e.target.value],
                          ]),
                        (e) =>
                          setMarkerCoordinates([
                            [e.target.value, markerCoordinates[0][1]],
                          ])
                      )
                    : utmComponent(0)}
                </div>
                {inputType === "latlon" && (
                  <div
                    id="enable-marker-btn"
                    className="mt-2 flex justify-center items-center bg-primary-lightBlue p-1 w-full hover:bg-hover-lightBlue hover:cursor-pointer transition duration-300"
                    onClick={() => enableMarker()}
                  >
                    <CiLocationOn />
                    <p className="ml-2 mb-0">Pin Marker</p>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            {polygonCoordinates && (
              <div>
                <div>
                  <div className="mb-2">
                    <div>
                      <div className="flex justify-center">
                        <div className="w-3/4 flex justify-center items-center mb-2 border-b border-white">
                          <p className="cursor-default text-large text-center mb-0 mr-2">
                            Sudut Atas
                          </p>
                          <RxCornerTopLeft />
                        </div>
                      </div>
                      {inputType === "latlon"
                        ? latLonComponent(
                            polygonCoordinates[3][1],
                            polygonCoordinates[3][0],
                            (e) =>
                              setTopLeftCoord([
                                topLeftCoord[0],
                                e.target.value,
                              ]),
                            (e) =>
                              setTopLeftCoord([e.target.value, topLeftCoord[1]])
                          )
                        : utmComponent(0)}
                    </div>
                  </div>
                  <div className="mb-2">
                    <div>
                      <div className="flex justify-center">
                        <div className="w-3/4 flex justify-center items-center mb-2 border-b border-white">
                          <p className="cursor-default text-large text-center mb-0 mr-2">
                            Sudut Bawah
                          </p>
                          <RxCornerBottomRight />
                        </div>
                      </div>
                      {inputType === "latlon"
                        ? latLonComponent(
                            polygonCoordinates[1][1],
                            polygonCoordinates[1][0],
                            (e) =>
                              setBottomRightCoord([
                                bottomRightCoord[0],
                                e.target.value,
                              ]),
                            (e) =>
                              setBottomRightCoord([
                                e.target.value,
                                bottomRightCoord[1],
                              ])
                          )
                        : utmComponent(1)}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {inputType === "latlon" && (
              <div
                id="gambar-aoi-btn"
                className="mt-2 flex justify-center items-center bg-primary-lightBlue p-1 w-full hover:bg-hover-lightBlue hover:cursor-pointer transition duration-300"
                onClick={() => enableDrawAOI()}
              >
                <TbDeviceIpadHorizontalPlus />
                <p className="ml-2 mb-0">Gambar AOI</p>
              </div>
            )}
          </div>
        )}
      </div>
      {/* RENTANG WAKTU */}
      <div className="mb-4">
        <p className="cursor-default text-xl text-center border border-white p-1 mb-1">
          Rentang Waktu
        </p>
        <div className="flex">
          <input
            type="time"
            disabled={isShowDetail ? false : true}
            onInput={(e) => handleChangeWaktu(e, "awal")}
            className="mr-1 bg-primary-darkBlue w-1/2 focus:outline-none text-center"
          />
          <input
            type="date"
            disabled={isShowDetail ? false : true}
            onInput={(e) => handleChangeTanggal(e, "awal")}
            className="bg-primary-darkBlue w-1/2 h-[45px] text-center"
          />
        </div>
        <p className="cursor-default text-large text-center my-1">
          Sampai Dengan
        </p>
        <div className="flex">
          <input
            type="time"
            disabled={isShowDetail ? false : true}
            onInput={(e) => handleChangeWaktu(e, "akhir")}
            className="mr-1 bg-primary-darkBlue w-1/2 focus:outline-none text-center"
          />
          <input
            type="date"
            disabled={isShowDetail ? false : true}
            onInput={(e) => handleChangeTanggal(e, "akhir")}
            className="bg-primary-darkBlue w-1/2 h-[45px] text-center"
          />
        </div>
      </div>
      {/* CLOUD COVER */}
      <div className="mb-4">
        <p className="cursor-default text-xl text-center border border-white p-1 mb-1">
          Maximum Cloud Cover
        </p>
        <div className="flex text-large">
          <p className="cursor-default text-large my-1 w-2/5">Level</p>
          <p className="text-large mb-0 mr-2">:</p>
          <select
            name="cloudCoverLevel"
            id="cloudCoverLevel"
            className="w-3/5 rounded-sm bg-primary-darkBlue px-2"
            onChange={(e) => handleChangeCloudCover(e)}
          >
            <option value="0%">0%</option>
            <option value="10%">10%</option>
            <option value="20%">20%</option>
          </select>
        </div>
      </div>
      {/* URGENSI */}
      <div>
        <p className="cursor-default text-xl text-center border border-white p-1 mb-1">
          Urgensi
        </p>
        <div className="flex text-large">
          <p className="cursor-default text-large my-1 w-2/5">Level</p>
          <p className="text-large mb-0 mr-2">:</p>
          <select
            name="urgensiLevel"
            id="urgensiLevel"
            className="w-3/5 rounded-sm bg-primary-darkBlue px-2"
            onChange={(e) => handleChangeUrgensiLevel(e)}
          >
            <option value="RENDAH">Rendah</option>
            <option value="SEDANG">Sedang</option>
            <option value="TINGGI">Tinggi</option>
          </select>
        </div>
      </div>
      <button
        disabled={isShowDetail ? false : true}
        type="submit"
        className="mt-14 flex justify-center items-center bg-primary-green p-1 text-2xl w-full hover:bg-hover-green transition duration-300"
      >
        {!loadingMsg && (
          <>
            <IoIosSend />
            <p className="ml-2 mb-0">Submit</p>
          </>
        )}
        {loadingMsg && <p className="ml-2 mb-0">{loadingMsg}</p>}
      </button>
    </form>
  );
};

export default SendDetail;
