import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "../Utils/KeycloakConnection"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LandingRoute from "./LandingRoute";
import Login from "../Views/Pages/Login/Login";
import Sidebar from "../Views/Components/Sidebar";
import MainMap from "../Views/Pages/MainMap/MainMap";
import ApprovalDetail from "../Views/Pages/Approval/ApprovalDetail";
import HistoryOperator from "../Views/Pages/Operator/ApprovalList";
import ListUser from "../Views/Pages/Operator/ListUser/TableUser"
import HistoryUser from "../Views/Pages/HistoryList/HistoryList";
import Profile from "../Views/Pages/Profile/Profile";

function AppRoutes() {
  return (
    <div>
      <ReactKeycloakProvider authClient={keycloak}>
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <LandingRoute>
                  <Login />
                </LandingRoute>
              }
            />
            <Route
              path="/map"
              element={
                <PrivateRoute
                  onlyRole='user'
                >
                  <MainMap />
                </PrivateRoute>
              }
            />
            <Route
              path="/riwayat"
              element={
                <PrivateRoute>
                  <Sidebar />
                  <HistoryUser />
                </PrivateRoute>
              }
            />
            <Route
              path="/detail/:page_id"
              element={
                <PrivateRoute>
                  <Sidebar />
                  <ApprovalDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/pengajuan"
              element={
                <PrivateRoute>
                  <Sidebar />
                  <HistoryOperator />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/daftar-user"
              element={
                <PrivateRoute
                  onlyRole='operator'
                >
                  <Sidebar />
                  <ListUser />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/profil"
              element={
                <PrivateRoute>
                  <Sidebar />
                  <Profile />
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  );
}

export default AppRoutes;