import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import Loader from "../Views/Components/Loader/Loader";
import { useEffect } from "react";
import { UserProvider } from "../Context/UserContext";
import useKeycloakRoles from "../Hooks/useKeycloakRoles";

const PrivateRoute = ({ onlyRole, children }) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const isLoggedIn = keycloak.authenticated;

  useEffect(() => {
    if (isLoggedIn !== undefined) {
      if (isLoggedIn === false) return navigate('/');

      if (onlyRole === 'operator' && !keycloak.hasResourceRole('operator')) return navigate('/map');

      if (onlyRole === 'user' && !keycloak.hasResourceRole('user')) return navigate('/pengajuan');
    }
  }, [isLoggedIn, navigate])

  if (isLoggedIn === undefined) {
    return (
      <Loader />
    )
  }

  if (isLoggedIn === true) {
    return (
      <UserProvider>
        {children}
      </UserProvider>
    )
  }
};

export default PrivateRoute;