// import { useEffect, useState } from 'react';
import '../ApprovalList.css';
import { FaEdit } from "react-icons/fa";
import ReactDataTable from '@ashvin27/react-datatable';
import { useEffect, useState } from 'react';
import { getAllProfile } from '../../../../Services/pengguna';
import { useKeycloak } from '@react-keycloak/web';

const dataUser = [
    {
        id_profil: 1,
        name_user: 'Jane Da',
        password: 'pass123',
        instansi: 'TNI AL',
        unit: 'pusdikhub',
    },
]
export default function TableUser() {
    const [userList, setUserList] = useState([]);
    const { keycloak } = useKeycloak();
    const originalData = [...dataUser];
    const handleButtonClick = (id) => {

    }

    const columns = [
        {
            key: 'unit',
            text: 'Unit',
            className: "text-center",
            sortable: true,
            align: 'center',
        },
        {
            key: 'instansi',
            text: 'Instansi',
            className: "text-center",
            sortable: true,
            align: 'center',
        },
        // {
        //     key: 'Aksi',
        //     text: '',
        //     className: "text-center",
        //     sortable: false,
        //     cell:
        //         (records) => (
        //             <button onClick={() => handleButtonClick(records.id_profil)}>
        //                 <FaEdit className='text-2xl' />
        //             </button>
        //         )
        // },
    ];
    const config = {
        key_column: 'id_submission',
        page_size: 25,
        length_menu: [10, 20, 50],
        show_length_menu: false,
        show_info: false,
    };

    useEffect(() => {
        getAllProfile(keycloak.token).then((res) => {
            setUserList(res.data);
        }).catch((err) => console.error(err));
    }, [])

    return (
        <div className="bg-primary-darkerBlue min-h-screen">
            <div className="border-b border-gray-300 flex justify-center pt-4 pb-2 bg-primary-darkBlue">
                <div className="w-[85%] flex flex-col">
                    <h2 className="mb-4 font-semibold leading-7 text-white ">Daftar Pengguna</h2>
                    <p className='text-white'>Halaman ini menampilkan daftar profil seluruh pengguna.</p>
                </div>
            </div>
            <div className="flex justify-center pt-4">
                <div className="w-[85%] flex flex-col bg-primary-darkBlue p-8 rounded-md h-max">
                    <ReactDataTable
                        columns={columns}
                        records={userList}
                        config={config}
                    />
                </div>
            </div>
        </div>
    )
}