import { transform } from 'ol/proj';

const ShowCoordinates = ({ map }) => {
  if (!map) {
    return null;
  }

  const coordinatesElement = document.getElementById('coordinates-display');

  const pointerMoveHandler = (evt) => {
    const coordinates = evt.coordinate;
    const lonLatCoordinate = transform(
      coordinates,
      'EPSG:3857',
      'EPSG:4326'
    );
    const longitude = lonLatCoordinate[0];
    const latitude = lonLatCoordinate[1];
    coordinatesElement.innerHTML = `${latitude.toFixed(6)}, ${longitude.toFixed(6)}`;
  };

  map.on('pointermove', pointerMoveHandler);
  return null;
};

export default ShowCoordinates;