import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { FaClipboardList } from "react-icons/fa";
import { IoInformationCircleSharp } from "react-icons/io5";
import ReactDataTable from '@ashvin27/react-datatable';
import { getPengajuanAll } from '../../../Services/pengajuan';
import './ApprovalList.css';

export default function History() {
  const statusColorHandler = {
    MENUNGGU: () => 'bg-primary-yellow text-white ',
    DISETUJUI: () => 'bg-primary-lightBlue text-white',
    SELESAI: () => 'bg-primary-green text-white',
    DITOLAK: () => 'bg-primary-red text-white',
    DIBATALKAN: () => 'bg-primary-red text-white',
    RENDAH: () => 'bg-primary-green text-white',
    SEDANG: () => 'bg-primary-yellow text-white',
    TINGGI: () => 'bg-primary-red text-white',
  };

  const getStatusColor = (x) => (statusColorHandler[x] || '')();

  const [approvalListData, setApprovalListData] = useState();
  const [info, setInfo] = useState('Tidak ada keterangan.');
  const [showInfo, setShowInfo] = useState(false);
  const [overlayPosition, setOverlayPosition] = useState({ top: 0, left: 0 });
  const { keycloak } = useKeycloak();
  const overlayRef = useRef(null);

  const columns = [
    {
      key: 'judul_pengajuan',
      text: 'Judul Pengajuan',
      className: "text-center",
      sortable: true,
      align: 'center',
    },
    {
      key: 'instansi',
      text: 'Instansi',
      className: "text-center",
      sortable: true,
      align: 'center',
      cell: (record) => {
        return record?.Pengguna?.instansi
      },
    },
    {
      key: 'createdAt',
      text: 'Tanggal Pengajuan',
      className: "",
      sortable: true,
      align: 'center',
      cell: (record) => {
        return convertTimestamp(record?.createdAt)
      },
    },
    {
      key: 'tanggal_awal_pengajuan',
      text: 'Tanggal Awal',
      className: "",
      sortable: true,
      align: 'center',
      cell: (record) => {
        return (record?.tanggal_awal_pengajuan).split('-').reverse().join('/')
      },
    },
    {
      key: 'tanggal_akhir_pengajuan',
      text: 'Tanggal Akhir',
      sortable: true,
      align: 'center',
      cell: (record) => {
        return (record?.tanggal_akhir_pengajuan).split('-').reverse().join('/')
      },
    },
    {
      key: 'urgensi',
      text: 'Urgensi',
      className: "text-center",
      sortable: true,
      align: 'center',
      cell: (records) => (
        <div className={`statusbox text-center ${getStatusColor(records.urgensi)}`}>
          {records.urgensi}
        </div>
      ),
    },
    {
      key: 'status',
      text: 'Status',
      className: `text-center text-white`,
      sortable: true,
      align: 'center',
      cell: (records) => (
        <div className={`statusbox text-center ${getStatusColor(records.status)}`}>
          {records.status}
        </div>
      ),
    },
    {
      key: 'Aksi',
      text: '',
      className: "",
      sortable: false,
      cell:
        (records) => (
          <div className='flex items-center justify-center'>
            <Link to={`/detail/${records.id}`}>
              <FaClipboardList className='text-2xl text-black text-center' />
            </Link>
            <button className='ml-1 text-2xl' onClick={(e) => handleShowInfo(e, records.info_tambahan)}>
              <IoInformationCircleSharp />
            </button>
          </div>
        )
    },
  ];

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (overlayRef.current && !overlayRef.current.contains(e.target)) {
        setShowInfo(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleShowInfo = (e, info_tambahan) => {
    const buttonRect = e.target.getBoundingClientRect();
    const buttonPosition = {
      top: buttonRect.top + window.scrollY,
      left: buttonRect.left + window.scrollX,
      width: buttonRect.width,
      height: buttonRect.height,
    };

    const overlayTop = buttonPosition.top - 150;
    const overlayLeft = buttonPosition.left + buttonPosition.width / 2 - 200;

    setOverlayPosition({ top: overlayTop, left: overlayLeft });
    if (info_tambahan === "" || !info_tambahan) {
      setInfo("Tidak ada keterangan.")
    } else {
      setInfo(info_tambahan);
    }
    setShowInfo(true);
  }

  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    date.setHours(date.getHours() + 7);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds} GMT +7`;

    return formattedDate;
  };

  const config = {
    key_column: 'id_submission',
    page_size: 25,
    length_menu: [10, 20, 50],
    show_length_menu: false,
    show_info: false,
  };

  useEffect(() => {
    getPengajuanAll(keycloak.token).then((res) => {
      let filteredData = res.data.filter((el) => (el.status === "MENUNGGU" || el.status === "DISETUJUI"));
      filteredData = filteredData.map((el) => {
        return {
          ...el.Pengguna,
          ...el,
        }
      });
      setApprovalListData(filteredData);
    }).catch(err => console.error(err));
  }, [])
  return (
    <div className="bg-primary-darkerBlue min-h-screen">
      <div className="border-b border-gray-300 flex justify-center pt-4 pb-2 bg-primary-darkBlue">
        <div className="w-[85%] flex flex-col">
          <h2 className="mb-4 font-semibold leading-7 text-white ">Daftar Pengajuan</h2>
          <p className='text-white'>Halaman ini menampilkan daftar pengajuan aktif yang sedang dalam tahap proses.</p>
        </div>
      </div>
      <div className="flex justify-center pt-4">
        <div className="w-[85%] flex flex-col bg-primary-darkBlue p-8 rounded-md h-max">
          <ReactDataTable
            columns={columns}
            records={approvalListData}
            config={config}
          />
        </div>
      </div>
      {showInfo && (
        <div
          ref={overlayRef}
          className="fixed"
          style={{ top: overlayPosition.top, left: overlayPosition.left }}
        >
          <div className="bg-white border py-2 pl-2.5 pr-4 w-52 h-32 rounded shadow-lg relative overflow-auto">
            <p className='mb-0 text-sm'>{info}</p>
            <button
              onClick={() => setShowInfo(false)}
              className="absolute top-0 right-2 mt-2 mr-2 text-sm text-gray-600 font-bold"
            >
              X
            </button>
            <div className="absolute -bottom-2 right-1 border-b-[16px] border-r-[16px] border-white transform -rotate-45"></div></div>
        </div>
      )}
    </div>
  )
}