// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control{
  color: black;
  width: 28rem !important;
  background-image: url('https://www.w3schools.com/css/searchicon.png');
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 8px 20px 8px 40px !important;
}

.form-control::placeholder{
	color: rgb(95, 95, 95) !important;
  
}
 
.col-md-6.float-right.text-right{
  width: 100%;
}

.table_tools{
  display: unset !important;
}

.statusbox{
  border-radius: 5px;
  display: inline-block;
  color: black;
  background-color: #ff001e;
  min-width: 110px; 
  min-height: 28px;
  box-sizing: content-box; 
}

`, "",{"version":3,"sources":["webpack://./src/Views/Pages/Operator/ApprovalList.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;EACvB,qEAAqE;EACrE,8BAA8B;EAC9B,4BAA4B;EAC5B,qCAAqC;AACvC;;AAEA;CACC,iCAAiC;;AAElC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".form-control{\n  color: black;\n  width: 28rem !important;\n  background-image: url('https://www.w3schools.com/css/searchicon.png');\n  background-position: 10px 10px;\n  background-repeat: no-repeat;\n  padding: 8px 20px 8px 40px !important;\n}\n\n.form-control::placeholder{\n\tcolor: rgb(95, 95, 95) !important;\n  \n}\n \n.col-md-6.float-right.text-right{\n  width: 100%;\n}\n\n.table_tools{\n  display: unset !important;\n}\n\n.statusbox{\n  border-radius: 5px;\n  display: inline-block;\n  color: black;\n  background-color: #ff001e;\n  min-width: 110px; \n  min-height: 28px;\n  box-sizing: content-box; \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
