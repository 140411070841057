// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  height: 100vh;
  width: 100vw;;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #212b35;
}
@keyframes rotating {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
.rotating_logo {
  animation: rotating 3s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/Loader/Loader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;AAcA;EACE;IAKE,wBAAwB;EAC1B;EACA;IAKE,0BAA0B;EAC5B;AACF;AACA;EAKE,sCAAsC;AACxC","sourcesContent":[".loader {\n  height: 100vh;\n  width: 100vw;;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #212b35;\n}\n\n@-webkit-keyframes rotating /* Safari and Chrome */ {\n  from {\n    -webkit-transform: rotateY(0deg);\n    -o-transform: rotateY(0deg);\n    transform: rotateY(0deg);\n  }\n  to {\n    -webkit-transform: rotateY(360deg);\n    -o-transform: rotateY(360deg);\n    transform: rotateY(360deg);\n  }\n}\n@keyframes rotating {\n  from {\n    -ms-transform: rotateY(0deg);\n    -moz-transform: rotateY(0deg);\n    -webkit-transform: rotateY(0deg);\n    -o-transform: rotateY(0deg);\n    transform: rotateY(0deg);\n  }\n  to {\n    -ms-transform: rotateY(360deg);\n    -moz-transform: rotateY(360deg);\n    -webkit-transform: rotateY(360deg);\n    -o-transform: rotateY(360deg);\n    transform: rotateY(360deg);\n  }\n}\n.rotating_logo {\n  -webkit-animation: rotating 3s linear infinite;\n  -moz-animation: rotating 3s linear infinite;\n  -ms-animation: rotating 3s linear infinite;\n  -o-animation: rotating 3s linear infinite;\n  animation: rotating 3s linear infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
