import React from 'react'
import HashLoader from "react-spinners/HashLoader";
import './Loader.css';

const Loader = () => {
  return (
    <div className='loader'>
      <img className='rotating_logo w-[25%]' src="/assets/Logo_Kementerian_Pertahanan_Republik_Indonesia.png" alt="logo_loader" />
    </div>
  )
}

export default Loader