import { createContext, useContext, useEffect, useState } from "react";
import { createNewProfile, getProfileById } from "../Services/pengguna";
import { useKeycloak } from "@react-keycloak/web";

const UserContext = createContext();

export const useUserContext = () => {
    return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
    const { keycloak } = useKeycloak();
    const [profileData, setProfileData] = useState({
        instansi: '',
        unit: '',
        jalur_avatar: ''
    });

    useEffect(() => {
        getProfileById(keycloak.idTokenParsed?.sub, keycloak.token).then((res) => {
            setProfileData(res.data);
        }).catch(async (error) => {
            if (error?.response?.status === 404) {
                try {
                    const res = await createNewProfile(keycloak.token, keycloak?.idTokenParsed?.preferred_username);
                    setProfileData(res.data);
                } catch (error) {
                    console.error(error);
                }
            }
            console.error(error);
        });
    }, [])

    return (
        <UserContext.Provider value={profileData}>
            {children}
        </UserContext.Provider>
    )
}