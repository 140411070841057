import AppRoutes from "../Routes/AppRoutes";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './App.css';

function App() {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
