import React from 'react';

import bgImage from "./assets/nasa_background.jpg"
import logo from "./assets/blacksky_logo.png"
import logoLen from "./assets/Logo_Len_Industri_Baru.png"
import logoKemhan from "./assets/Logo_Kementerian_Pertahanan_Republik_Indonesia.png"

import "./Login.css"
import { useKeycloak } from '@react-keycloak/web';

const Login = () => {

  const { keycloak } = useKeycloak();

  return (
    <div id="login-page">
      <div className="image">
        <img src={bgImage} alt='landing-background' loading='lazy'></img>
      </div>
      <div className="card area-card">
        <div className="card-body d-flex flex-column align-items-center justify-content-center text-white" style={{ border: "0" }}>
          <div className='flex items-center justify-center w-full mb-4'>
            <img className='w-[30%] mr-2' src={logoKemhan} alt="logo" loading='lazy' />
            <div className='font-bold font-serif text-3xl'>
              <p className='m-0'>Kementerian</p>
              <p className='m-0'>Pertahanan</p>
            </div>
          </div>
          <div className='flex flex-column items-center justify-between w-full mt-4'>
            <img className='w-2/3' src={logo} alt="logo" loading='lazy' />
          </div>
          <p className="title-2 mt-2 mb-0" style={{ color: "#FFFFFF" }}>SPCS</p>
          <p className="title-3 mb-4" style={{ color: "#FFFFFF" }}>Sistem Permohonan Citra Satelit</p>
          <div>
            <div className="d-grid">
              <button onClick={() => keycloak.login({ redirectUri: `${process.env.REACT_APP_ROOT_URL}/map` })} className="btn btn-dark btn-block" type="button" >Masuk</button>
            </div>
          </div>
          <div className='flex flex-column items-center mt-[4rem]'>
            <p className='text-white mb-0'>Powered by:</p>
            <img className='w-[30%]' src={logoLen} alt="Logo Len" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;