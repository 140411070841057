import axios from "axios";

const BASE_URL = process.env.REACT_APP_BE_URL;

const postPengajuan = async (data, token) => {
  try {
    return await axios.post(`${BASE_URL}/pengajuan/membuat`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

const getPengajuanAll = async (token) => {
  try {
    return await axios.get(`${BASE_URL}/pengajuan`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

const getPengajuanDetail = async (id, token) => {
  try {
    return await axios.get(`${BASE_URL}/pengajuan/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

const putPengajuanStatus = async (id, data, token) => {
  try {
    return await axios.put(`${BASE_URL}/pengajuan/status/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

const putPengajuanInfo = async (id, data, token) => {
  try {
    return await axios.put(`${BASE_URL}/pengajuan/info_tambahan/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export {
  postPengajuan,
  getPengajuanDetail,
  putPengajuanStatus,
  getPengajuanAll,
  putPengajuanInfo,
};
