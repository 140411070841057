import '../Operator/ApprovalList.css';
import { FaClipboardList } from "react-icons/fa";
import ReactDataTable from '@ashvin27/react-datatable';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getPengajuanAll } from '../../../Services/pengajuan';
import { useKeycloak } from '@react-keycloak/web';

export default function History() {
  const statusColorHandler = {
    MENUNGGU: () => 'bg-primary-yellow text-white',
    DISETUJUI: () => 'bg-primary-lightBlue text-white',
    SELESAI: () => 'bg-primary-green text-white',
    DITOLAK: () => 'bg-primary-red text-white',
    DIBATALKAN: () => 'bg-primary-red text-white',
    RENDAH: () => 'bg-primary-green text-white',
    SEDANG: () => 'bg-primary-yellow text-white',
    TINGGI: () => 'bg-primary-red text-white',
  };

  const getStatusColor = (x) => (statusColorHandler[x] || '')();

  const [historyListData, setHistoryListData] = useState();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const columns = [
    {
      key: 'judul_pengajuan',
      text: 'Judul Pengajuan',
      className: "text-center",
      sortable: true,
      align: 'center',
    },
    {
      key: 'instansi',
      text: 'Instansi',
      className: "text-center",
      sortable: true,
      align: 'center',
      cell: (record) => {
        return record?.Pengguna?.instansi
      },
    },
    {
      key: 'createdAt',
      text: 'Tanggal Pengajuan',
      className: "",
      sortable: true,
      align: 'center',
      cell: (record) => {
        return convertTimestamp(record?.createdAt)
      },
    },
    {
      key: 'tanggal_awal_pengajuan',
      text: 'Tanggal Awal',
      className: "",
      sortable: true,
      align: 'center',
      cell: (record) => {
        return (record?.tanggal_awal_pengajuan).split('-').reverse().join('/')
      },
    },
    {
      key: 'tanggal_akhir_pengajuan',
      text: 'Tanggal Akhir',
      sortable: true,
      align: 'center',
      cell: (record) => {
        return (record?.tanggal_akhir_pengajuan).split('-').reverse().join('/')
      },
    },
    {
      key: 'urgensi',
      text: 'Urgensi',
      className: "text-center",
      sortable: true,
      align: 'center',
      cell: (records) => (
        <div className={`statusbox text-center ${getStatusColor(records.urgensi)}`}>
          {records.urgensi}
        </div>
      ),
    },
    {
      key: 'status',
      text: 'Status',
      className: `text-center `,
      sortable: true,
      align: 'center',
      cell: (records) => (
        <div className={`statusbox text-center ${getStatusColor(records.status)}`}>
          {records.status}
        </div>
      ),
    },
    {
      key: 'Aksi',
      text: '',
      className: "",
      sortable: false,
      cell:
        (records) => (
          <Link to={`/detail/${records.id}`}>
            <FaClipboardList className='text-2xl text-black text-center' />
          </Link>
        )
    },
  ];

  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    date.setHours(date.getHours() + 7);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds} GMT +7`;

    return formattedDate;
  };

  const config = {
    key_column: 'id_submission',
    page_size: 25,
    length_menu: [10, 20, 50],
    show_length_menu: false,
    show_info: false,
  };

  useEffect(() => {
    getPengajuanAll(keycloak.token).then((res) => {
      let filteredData = res.data.filter((el) => (el.status !== "MENUNGGU" && el.status !== "DISETUJUI"));
      filteredData = filteredData.map((el) => {
        return {
          ...el.Pengguna,
          ...el,
        }
      });
      setHistoryListData(filteredData);
    }).catch(err => console.error(err));
  }, [])

  return (
    <div className="bg-primary-darkerBlue min-h-screen">
      <div className="border-b border-gray-300 flex justify-center pt-4 pb-2 bg-primary-darkBlue">
        <div className="w-[85%] flex flex-col">
          <h2 className="mb-4 font-semibold leading-7 text-white ">Riwayat Pengajuan</h2>
          <p className='text-white'>Halaman ini menampilkan daftar pengajuan yang telah diterima, ditolak atau dibatalkan.</p>
        </div>
      </div>
      <div className="flex justify-center pt-4">
        <div className="w-[85%] flex flex-col bg-primary-darkBlue p-8 rounded-md h-max">
          <ReactDataTable
            columns={columns}
            records={historyListData}
            config={config}
          />
        </div>
      </div>
    </div>
  )
}