import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import Loader from "../Views/Components/Loader/Loader";
import { useEffect } from "react";

const LandingRoute = ({ children }) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const isLoggedIn = keycloak.authenticated;

  useEffect(() => {
    if (isLoggedIn !== undefined) {
      if (isLoggedIn === true) return navigate('/map');
    }
  }, [isLoggedIn, navigate])


  if (isLoggedIn === undefined) {
    return (
      <Loader />
    )
  }

  if (isLoggedIn === false) return children;

};

export default LandingRoute;