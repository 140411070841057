import React from "react";
import './LayerList.css';
import { IoTriangle } from "react-icons/io5";
import logo from "../assets/blacksky_logo.png"
import baseMap from "../assets/base_map.png"
import darkMap from "../assets/dark_map.png"
import satelliteMap from "../assets/satellite_map.png"

const LayerList = ({ setSelectedLayer }) => {

  return (
    <div className="absolute test top-[150px] left-[80px] bg-primary-purple bg-opacity-70 text-white text-large h-max-40 w-36 rounded-sm">
      <div className="w-full">
        <div onClick={() => setSelectedLayer('osm')} className="flex cursor-pointer justify-start items-center bg-primary-purple bg-opacity-0 hover:bg-opacity-85 transition duration-300">
          <div className="overflow-hidden rounded-full ml-1 mr-1.5 my-1 border border-white">
            <img
              src={baseMap}
              alt="Base Map"
              className="w-8 h-8"
            />
          </div>
          <p className="m-0">Base Map</p>
        </div>
        <div onClick={() => setSelectedLayer('dark')} className="flex cursor-pointer justify-start items-center bg-primary-purple bg-opacity-0 hover:bg-opacity-85 transition duration-300">
          <div className="overflow-hidden rounded-full ml-1 mr-1.5 my-1 border border-white">
            <img
              src={darkMap}
              alt="Dark Map"
              className="w-8 h-8"
            />
          </div>
          <p className="m-0">Dark Map</p>
        </div>
        <div id="last-layer-list" onClick={() => setSelectedLayer('satellite')} className="flex cursor-pointer justify-start items-center bg-primary-purple bg-opacity-0 hover:bg-opacity-85 transition duration-300">
          <div className="overflow-hidden rounded-full ml-1 mr-1.5 my-1 border border-white">
            <img
              src={satelliteMap}
              alt="Satellite Map"
              className="w-8 h-8"
            />
          </div>
          <p className="m-0">Satellite</p>
        </div>
        <div className="triangle-container absolute h-4 w-4 -left-[15px] bottom-0 transform -rotate-90">
          <IoTriangle id="triangle" className="text-primary-purple opacity-50" />
        </div>
      </div>
    </div>
  )
}

export default LayerList;