import { useEffect, useState } from "react";
import Feature from "ol/Feature";
import Overlay from "ol/Overlay";
import Point from "ol/geom/Point";
import Style from "ol/style/Style.js";
import Icon from "ol/style/Icon.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { transform } from "ol/proj";

const LocationMarker = ({ map, vectorSource, setPointLayer, setMarkerCoordinates }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    // REMOVE PREVIOUS POINT LAYER
    map.getLayers().forEach((layer) => {
      if (layer) {
        if (layer.get("id") === "point-vector") {
          map.removeLayer(layer);
        }
      }
    });
    const pointLayer = new VectorLayer({
      id: "point-vector",
      source: vectorSource,
    });

    const markerIconStyle = new Style({
      image: new Icon({
        src: "./assets/Marker.png",
        scale: 0.075,
        anchor: [0.5, 1],
      }),
    });
    map.addLayer(pointLayer);
    setPointLayer(pointLayer);

    // COORDINATES OVERLAY ELEMENT
    const overlayElement = document.createElement("div");
    overlayElement.id = "coordinates-overlay";

    const clickHandler = (event) => {
      if (vectorSource) {
        const coordinate = event.coordinate;
        const lonLatCoordinate = transform(
          coordinate,
          "EPSG:3857",
          "EPSG:4326"
        );
        const latitude = lonLatCoordinate[1];
        const longitude = lonLatCoordinate[0];
        setMarkerCoordinates([[longitude.toFixed(6), latitude.toFixed(6)]]);

        const overlayClassName =
          "bg-primary-purple text-white p-2 rounded-sm bg-opacity-85 bottom-[5%] left-[45%] block select-text";
        if (document.getElementById("coordinates-overlay")) {
          document.getElementById("coordinates-overlay").remove();
        }
        const elementsToRemove = document.getElementsByClassName(overlayClassName);
        if (elementsToRemove) {
          const elementsArray = Array.from(elementsToRemove);
          elementsArray.forEach((element) => {
            element.remove();
          });
        }

        // REMOVE PREVIOUS POINT MARKER
        vectorSource.removeFeature(
          vectorSource.getFeatureById("marker-point-feature")
        );

        const newMarkerFeature = new Feature({
          geometry: new Point(coordinate),
        });

        newMarkerFeature.setId("marker-point-feature");
        newMarkerFeature.setStyle(markerIconStyle);
        vectorSource.addFeature(newMarkerFeature);

        setSelectedLocation(coordinate);

        // COORDINATES OVERLAY
        overlayElement.innerHTML = `Latitude: ${latitude.toFixed(6)}<br>Longitude : ${longitude.toFixed(6)}`;

        const newPointOverlay = new Overlay({
          id: "coordinates-overlay",
          element: overlayElement,
          autoPan: true,
          className: overlayClassName,
        });

        map.addOverlay(newPointOverlay);
      }
    };

    map.on("click", clickHandler);

    return () => {
      map.un("click", clickHandler);
    };
  }, [map]);

  useEffect(() => {
    map.getLayers().forEach((layer) => {
      if (layer) {
        if (layer.get("id") === "point-vector") {
        }
      }
    });
  }, [selectedLocation]);

  return null;
};

export default LocationMarker;
