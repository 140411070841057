import Modal from 'react-modal';
import { IoMdClose } from "react-icons/io";
import './CustomModal.css';

const CustomModal = ({ modalState, closeModal, title = '', children }) => {
    return (
        <div>
            <Modal
                isOpen={modalState}
                onRequestClose={closeModal}
                className="CustomModal bg-primary-darkBlue text-white p-4 rounded-lg"
                appElement={document.getElementById('root') || undefined}
            >
                <div className='flex justify-between items-center mb-2'>
                    <p className='font-bold text-2xl'>{title}</p>
                    <button className='text-3xl' onClick={closeModal}><IoMdClose /></button>
                </div>
                {children}
            </Modal>
        </div>
    );
}

export default CustomModal;