import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { transform, fromLonLat } from 'ol/proj';
import { FaXmark } from "react-icons/fa6";

const SearchBar = ({ map, deleteOverlay, }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearch, setIsSearch] = useState(false);

  const handleReset = () => {
    if (!map) return;
    deleteOverlay('coordinates-overlay');
    const initialCenter = fromLonLat([118.8186111, -1.15]);
    const initialZoom = 5.34;

    map.getView().animate({
      center: initialCenter,
      zoom: initialZoom,
      duration: 750,
    });
  };

  const handleSearch = async (query) => {
    if (isSearch === false) {
      setIsSearch(true);
    }
    if (!map || isSearch === false) return;
    try {

      let newCenter;
      const isCoordinate = /^[-]?\d+(\.\d+)?,\s*[-]?\d+(\.\d+)?$/.test(query);

      if (isCoordinate) {
        const coordinates = query.split(',').map(Number);
        newCenter = transform(coordinates.reverse(), 'EPSG:4326', 'EPSG:3857');
      } else {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${query}`);
        const data = await response.json();

        if (data.length > 0) {
          const result = data[0];
          newCenter = transform([parseFloat(result.lon), parseFloat(result.lat)], 'EPSG:4326', 'EPSG:3857');
        } else {
          handleReset();
          return;
        }
      }

      const newZoom = 11;
      map.getView().animate({
        center: newCenter,
        zoom: newZoom,
        duration: 750,
      });

    } catch (error) {
      console.error('Terjadi kesalahan:', error);
    }
  };

  const handleClose = () => {
    setIsSearch(false)
  }

  const isValidCoordinate = (query) => {
    const parts = query.split(',');
    return parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1]);
  };

  return (
    <div>
      <div className={`bg-primary-purple hover:bg-opacity-85 transition duration-300 cursor-pointer rounded-sm bg-opacity-70 w-full h-12 flex justify-center items-center text-2xl text-white ${isSearch ? 'hidden' : ''}`} onClick={() => handleSearch(searchQuery)}>
        <FaSearch className='text-xl' />
      </div>
      <div className={`flex bg-primary-purple text-white rounded-sm bg-opacity-70 hover:bg-opacity-85 focus:bg-opacity-85 ${isSearch ? 'w-96' : 'hidden'} h-12 items-center px-[14px] mb-1`}>
        <div className='cursor-pointer' onClick={() => handleSearch(searchQuery)}>
          <FaSearch className='text-xl' />
        </div>

        <div className='flex items-center justify-between w-full'>
          <input
            type="text"
            placeholder={isSearch ? 'Masukkan nama daerah atau koordinat' : ''}
            className={`bg-transparent text-white placeholder-primary-lightGray ml-4 ${isSearch ? 'w-full' : ''} focus:outline-none focus:border-transparent`}
            disabled={isSearch ? false : true}
            value={isSearch ? searchQuery : ''}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch(searchQuery)}
          />

          <div className={`cursor-pointer ${isSearch ? '' : 'hidden'}`} onClick={() => handleClose()}>
            <FaXmark className='text-xl' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;