import { useEffect, useState } from "react";
import { Fill, Stroke, Style } from "ol/style";
import Draw from "ol/interaction/Draw.js";
import { Vector as VectorLayer } from "ol/layer.js";
import MousePosition from "ol/control/MousePosition";
import Overlay from "ol/Overlay";
import { createStringXY } from "ol/coordinate";
import { createBox } from "ol/interaction/Draw";
import { transform } from "ol/proj";

const DrawShape = ({
  map,
  shapeType,
  setIsShape,
  vectorSource,
  vectorShape,
  setVectorShape,
  setShapeLayer,
  setPolygonCoordinates,
}) => {
  const [areaCalcOverlay, setAreaCalcOverlay] = useState();
  const [drawArea, setDrawArea] = useState(0);
  const [formattedDrawArea, setFormattedDrawArea] = useState(0);
  const [mouseCoor, setMouseCoor] = useState();
  let shapeInteraction;

  useEffect(() => {
    if (shapeInteraction) {
      map.removeInteraction(shapeInteraction);
    }

    const addShapeInteraction = () => {
      if (vectorShape) {
        map.removeInteraction(vectorShape);
      }

      const mousePositionControl = new MousePosition({
        coordinateFormat: createStringXY(4),
        projection: "EPSG:4326",
        target: document.getElementById("mouse-position"),
        undefinedHTML: "&nbsp;",
      });

      // CREATE SHAPE
      switch (shapeType) {
        case "rectangle":
          shapeInteraction = new Draw({
            style: [
              new Style({
                fill: new Fill({
                  color: "rgba(255, 255, 255, 0.2)",
                }),
                stroke: new Stroke({
                  color: "rgba(0, 0, 255, 0.8)",
                }),
              }),
            ],
            source: vectorSource,
            type: "Circle",
            geometryFunction: createBox(),
          });
          break;
        case "circle":
          shapeInteraction = new Draw({
            style: [
              new Style({
                fill: new Fill({
                  color: "rgba(255, 255, 255, 0.2)",
                }),
                stroke: new Stroke({
                  color: "rgba(0, 0, 255, 0.8)",
                }),
              }),
            ],
            source: vectorSource,
            type: "Circle",
          });
          break;
        case "polygon":
          shapeInteraction = new Draw({
            style: [
              new Style({
                fill: new Fill({
                  color: "rgba(255, 255, 255, 0.2)",
                }),
                stroke: new Stroke({
                  color: "rgba(0, 0, 255, 0.8)",
                }),
              }),
            ],
            source: vectorSource,
            type: "Polygon",
          });
          break;
        default:
          break;
      }

      // DRAW START
      shapeInteraction.on("drawstart", (event) => {
        map.addControl(mousePositionControl);

        const feature = event.feature;
        const geometry = feature?.getGeometry();
        const pointerMoveHandler = (event) => {
          setMouseCoor(event.coordinate);
          if (geometry) {
            const area = calculateArea(geometry);
            setDrawArea(area);
          }
        };
        map.on("pointermove", pointerMoveHandler);

        map.removeOverlay(areaCalcOverlay);
      });

      // DRAW END
      shapeInteraction.on("drawend", (event) => {
        map.getControls().forEach((control) => {
          if (control === mousePositionControl) {
            map.removeControl(control);
            control.setTarget(null);
          }
        });

        // GET FEATURE
        const feature = event.feature;
        const geometry = feature.getGeometry();
        const area = calculateArea(geometry);
        const polygonCoordinates = geometry.getCoordinates()
          ? geometry.getCoordinates()[0]
          : null;
        const lonLatCoordinates = polygonCoordinates?.map((coordinate) =>
          transform(coordinate, "EPSG:3857", "EPSG:4326").map((coord) =>
            parseFloat(coord.toFixed(6))
          )
        );

        if (shapeType !== "circle") {
          // SAVE COORDINATES
          setPolygonCoordinates(lonLatCoordinates);
        }
        showFinalAreaCalc(geometry, area);

        // CONFIGURE SHAPE FEATURE
        feature.id = "shape-feature";
        feature.setStyle(
          new Style({
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
            stroke: new Stroke({
              color: "rgba(0, 0, 255, 0.8)",
            }),
          })
        );

        setIsShape(false);
        // AUTO ZOOM IN SNAP
        const extent = geometry.getExtent();
        map.getView().fit(extent, {
          duration: 350,
          size: [1600, 600],
          nearest: true,
        });

        // REMOVE PREVIOUS LAYER
        map.getLayers().forEach((layer) => {
          if (layer) {
            if (layer.get("id") === "shape-vector") {
              map.removeLayer(layer);
            }
          }
        });

        // ADD NEW LAYER
        const shapeLayer = new VectorLayer({
          source: vectorSource,
          id: "shape-vector",
        });
        map.addLayer(shapeLayer);
        setShapeLayer(shapeLayer);
        map.removeInteraction(shapeInteraction);
      });

      setVectorShape(shapeInteraction);
      map.addInteraction(shapeInteraction);
    };
    if (map) {
      addShapeInteraction();
    }
  }, [map, shapeType]);

  // AREA CALCULATION
  const calculateArea = (geometry) => {
    let area;
    let coordinates;

    switch (shapeType) {
      case "rectangle":
        coordinates = geometry.getCoordinates()[0];
        area = calculatePolygonArea(coordinates);
        break;
      case "circle":
        area = calculateCircleArea(geometry);
        break;
      case "polygon":
        coordinates = geometry.getCoordinates()[0];
        area = calculatePolygonArea(coordinates);
        break;
      default:
        break;
    }

    return area;
  };

  const calculatePolygonArea = (coordinates) => {
    const numVertices = coordinates.length;
    let area = 0;

    for (let i = 0; i < numVertices - 1; i++) {
      const xi = coordinates[i][0];
      const yi = coordinates[i][1];
      const xi1 = coordinates[i + 1][0];
      const yi1 = coordinates[i + 1][1];
      area += xi * yi1 - xi1 * yi;
    }

    area = Math.abs(area) / 2;
    const areaSquareKm = area / 1e6;

    return areaSquareKm;
  };

  const calculateCircleArea = (geometry) => {
    const radius = geometry.getRadius();
    const area = Math.PI * Math.pow(radius, 2);
    const areaSquareKm = area / 1e6;

    return areaSquareKm;
  };

  // DRAGGING OVERLAY
  useEffect(() => {
    setFormattedDrawArea(formatAreaNumber(Math.round(drawArea), true));
  }, [drawArea]);

  useEffect(() => {
    if (mouseCoor) {
      const pixelPosition = map.getPixelFromCoordinate(mouseCoor);
      const mouseElement = document.getElementById("mouse-position-element");
      const mouseElementTemp =
        document.getElementsByClassName("ol-mouse-position")[0];
      if (mouseElement) {
        mouseElement.style.left = `${pixelPosition[0] + 20}px`;
        mouseElement.style.top = `${pixelPosition[1] - 15}px`;
        mouseElement.innerHTML = `${formattedDrawArea} km²`;
      } else if (mouseElementTemp) {
        mouseElementTemp.id = "mouse-position-element";
        mouseElementTemp.style.left = `${pixelPosition[0]}px`;
        mouseElementTemp.style.top = `${pixelPosition[1]}px`;
        mouseElementTemp.classList.add(
          "bg-primary-purple",
          "text-white",
          "text-sm",
          "font-bold",
          "p-2",
          "rounded-sm",
          "bg-opacity-70",
          "w-fit"
        );
        mouseElementTemp.innerHTML = `${formattedDrawArea} km²`;
      }
    }
  }, [formattedDrawArea]);

  // MANAGE AREA OVERLAY ELEMENT
  const showFinalAreaCalc = (geometry, area) => {
    const overlayClassName =
      "bg-primary-purple text-white p-2 rounded-sm bg-opacity-85 bottom-[5%] left-[45%] block select-text";
    if (document.getElementById("area-overlay")) {
      document.getElementById("area-overlay").remove();
    }
    const elementsToRemove = document.getElementsByClassName(overlayClassName);
    if (elementsToRemove) {
      const elementsArray = Array.from(elementsToRemove);
      elementsArray.forEach((element) => {
        element.remove();
      });
    }

    setDrawArea(area);

    const formattedArea = formatAreaNumber(Math.round(area), true);
    const overlayElement = document.createElement("div");
    overlayElement.id = "area-overlay";
    overlayElement.textContent = `Luas Area: ${formattedArea} km²`;

    const newLiveAreaOverlay = new Overlay({
      id: "area-overlay",
      element: overlayElement,
      autoPan: true,
      className: overlayClassName,
    });

    setAreaCalcOverlay(newLiveAreaOverlay);
    map.addOverlay(newLiveAreaOverlay);
  };

  const formatAreaNumber = (number, isInteger) => {
    const options = {
      minimumFractionDigits: isInteger ? 0 : 2,
      maximumFractionDigits: isInteger ? 0 : 2,
    };

    const formatter = new Intl.NumberFormat("id-ID", options);
    let formattedNumber = formatter.format(number);

    return formattedNumber;
  };

  return null;
};

export default DrawShape;
