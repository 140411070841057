import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import HashLoader from "react-spinners/HashLoader";
import useKeycloakRoles from "../../../Hooks/useKeycloakRoles";
import { getPengajuanDetail, putPengajuanStatus, putPengajuanInfo } from "../../../Services/pengajuan";
import { io } from "socket.io-client";
import { getPresignedBerkasByObject, uploadAnalysisResult, uploadBerkasForPengajuan } from "../../../Services/berkas";
import { FaFilePdf } from "react-icons/fa6";
import { BiScreenshot } from "react-icons/bi";

import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { OSM, Vector as VectorSource } from 'ol/source';
import { fromLonLat } from "ol/proj";
import { defaults } from 'ol/control';
import { approvalStatusNotes, formatBytes, parseApprovalStatus, parseUrgency } from "../../../Utils/Strings";
import { useNavigate, useParams } from "react-router-dom";
import { Feature } from 'ol';
import { Polygon } from 'ol/geom';
import { Style, Fill, Stroke } from 'ol/style';
import useModal from "../../../Hooks/useModal";
import { useScreenshot, createFileName } from "../../../Hooks/useScreenshot";
import useConfirmationModal from "../../../Hooks/useConfirmationModal";

const UserApprovalDetail = () => {
  const { page_id } = useParams();
  const navigate = useNavigate()
  const mapRef = useRef(null);
  const mapRefTiff = useRef(null);
  const pdfComponentRef = useRef(null);
  const [isOperator, isUser, isKabid] = useKeycloakRoles();
  const [info, setInfo] = useState("");
  const [contentData, setContentData] = useState("");
  const [approvalState, setApprovalState] = useState(0); // 0 means neutral, 1 means ready to accept, 2 means ready to reject
  const [approvalStatus, setApprovalStatus] = useState(-1); // 0 means MENUNGGU, 1 mean SELESAI, 2 means DITOLAK
  const [urgency, setUrgency] = useState(0); // 0 means RENDAH, 1 mean SEDANG, 2 means TINGGI
  const [uploadStatus, setUploadStatus] = useState('');
  const [alasanPenolakan, setAlasanPenolakan] = useState('');
  const [alasanPembatalan, setAlasanPembatalan] = useState('');
  const [presignedBerkas, setPresignedBerkas] = useState('');
  const [presignedBerkasMetadata, setPresignedBerkasMetadata] = useState('');
  const [presignedDokumen, setPresignedDokumen] = useState([]);
  const [presignedAttachment, setPresignedAttachment] = useState('');
  const [presignedHasilAnalisis, setPresignedHasilAnalisis] = useState('');
  const [presignedBerkasAnotasi, setPresignedBerkasAnotasi] = useState([]);
  const [openPreviewAttachmentModal, PreviewAttachmentModal] = useModal({ title: "Daftar Berkas Lampiran" });
  const [openPreviewDokumenModal, PreviewDokumenModal] = useModal({ title: "Daftar Berkas Tambahan" });
  const [openPreviewAnotasiModal, PreviewAnotasiModal] = useModal({ title: "Daftar Berkas Anotasi" });
  const [berkasTambahanList, setBerkasTambahanList] = useState([]);
  const [berkasImage, setBerkasImage] = useState(null);
  const [berkasAnotasiAircraft, setBerkasAnotasiAircraft] = useState(null);
  const [berkasAnotasVessel, setBerkasAnotasiVessel] = useState(null);
  const [berkasAnotasiBuilding, setBerkasAnotasiBuilding] = useState(null);
  const [berkasHasilAnalisis, setBerkasHasilAnalisis] = useState(null);
  const [fileValidationMsg, setFileValidationMsg] = useState('');
  const [berkasPreviewIdx, setBerkasPreviewIdx] = useState(0);
  const [tanggalCitra, setTanggalCitra] = useState('');
  const { keycloak } = useKeycloak();
  const berkasFormData = new FormData();
  const [handleModalConfirm, ConfirmationModal] = useConfirmationModal();

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  const download = (image, { name = contentData?.judul_pengajuan, extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(pdfComponentRef.current, { useCORS: true }).then(download);

  const osmLayer = new TileLayer({
    source: new OSM()
  })

  const initializeMap = () => {
    const map = new Map({
      target: 'map',
      layers: [osmLayer],
      view: new View({
        center: fromLonLat([118.8186111, -1.15]),
        zoom: 5.34,
      }),
      controls: defaults({ attribution: false })
    });
    mapRef.current = map;

    return map;
  }

  const initializePreviewMap = () => {
    const map = new Map({
      target: 'preview-map',
      layers: [osmLayer],
      view: new View({
        center: fromLonLat([118.8186111, -1.15]),
        zoom: 5.34,
      }),
      controls: defaults({ attribution: false })
    });
    mapRefTiff.current = map;

    return map;
  }

  useEffect(() => {
    initializeMap();
    initializePreviewMap();
  }, []);

  const handleOnFileChange = (e, setter) => {
    const file = e.target.files[0];

    setter(file);
  }

  const handleOnMultipleFileChange = (e, setter) => {
    const files = [];
    for (const file of e.target.files) {
      files.push(file);
    }

    setter(files);
  }

  const isFiletypeValid = (file, { excludeType = undefined, onlyType = undefined }) => {
    if (Array.isArray(file)) {
      return file.every(f => isFileValid(f, { excludeType, onlyType }));
    } else {
      return isFileValid(file, { excludeType, onlyType });
    }
  };

  const isFileValid = (file, { excludeType = undefined, onlyType = undefined }) => {
    if (excludeType && onlyType) {
      throw new Error("Cannot specify both excludeType and onlyType");
    }

    if (onlyType && file?.type.includes(onlyType) === false) {
      return false;
    }

    if (excludeType && file?.type.includes(excludeType) === true) {
      return false;
    }

    return true;
  };

  const handleUpload = async () => {
    try {
      const socket = io(`${process.env.REACT_APP_SOCKET_IO_URL}`);

      if (!isFiletypeValid(berkasImage, { onlyType: "image/tiff" })) {
        setFileValidationMsg("Gambar harus memiliki format berkas .tif atau .tiff!");
        return;
      }

      if (!isFiletypeValid(berkasTambahanList, { excludeType: "image" })) {
        setFileValidationMsg("Berkas tidak boleh memuat gambar!");
        return;
      }

      if (berkasAnotasiAircraft && !isFiletypeValid(berkasAnotasiAircraft, { onlyType: "application/zip" })) {
        setFileValidationMsg("Berkas harus memiliki format berkas .zip!");
        return;
      }

      if (berkasAnotasVessel && !isFiletypeValid(berkasAnotasVessel, { onlyType: "application/zip" })) {
        setFileValidationMsg("Berkas harus memiliki format berkas .zip!");
        return;
      }

      if (berkasAnotasiBuilding && !isFiletypeValid(berkasAnotasiBuilding, { onlyType: "application/zip" })) {
        setFileValidationMsg("Berkas harus memiliki format berkas .zip!");
        return;
      }

      setFileValidationMsg("");

      socket.on('connect', async () => {
        setUploadStatus('Uploading . . .');

        const socketId = socket.id;

        berkasFormData.append('socketId', socketId);
        berkasFormData.append('tanggal_gambar', tanggalCitra);

        berkasFormData.append('imageFile', berkasImage);

        if (berkasAnotasiAircraft) {
          berkasFormData.append('annotation-aircraft', berkasAnotasiAircraft);
        }

        if (berkasAnotasVessel) {
          berkasFormData.append('annotation-vessel', berkasAnotasVessel);
        }

        if (berkasAnotasiBuilding) {
          berkasFormData.append('annotation-building', berkasAnotasiBuilding);
        }

        if (berkasTambahanList?.length > 0) {
          for (const file of berkasTambahanList) {
            berkasFormData.append('documentFile', file);
          }
        }

        try {
          await uploadBerkasForPengajuan(page_id, berkasFormData, keycloak.token);

          await putPengajuanStatus(page_id, {
            status: 'SELESAI',
            alasan_penolakan: '',
            alasan_pembatalan: ''
          }, keycloak.token);

          navigate(0);
        } catch (error) {
          console.error(error);
          setUploadStatus('Uploads failed!');
        }

        socket.close();
      })

      socket.on('private', function (msg) {
        setUploadStatus(msg);
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleUploadHasilAnalisis = async () => {
    try {
      const socket = io(`${process.env.REACT_APP_SOCKET_IO_URL}`);

      if (!isFiletypeValid(berkasHasilAnalisis, { onlyType: "application/vnd.google-earth" })) {
        setFileValidationMsg("Berkas harus memiliki format berkas .kml atau .kmz!");
        return;
      }

      setFileValidationMsg("");

      socket.on('connect', async () => {
        setUploadStatus('Uploading . . .');

        const socketId = socket.id;

        berkasFormData.append('socketId', socketId);

        berkasFormData.append('analysisResultFile', berkasHasilAnalisis);

        try {
          await uploadAnalysisResult(contentData?.Berkas.id, berkasFormData, keycloak.token);

          navigate(0);
        } catch (error) {
          console.error(error);
          setUploadStatus('Uploads failed!');
        }

        socket.close();
      })

      socket.on('private', function (msg) {
        setUploadStatus(msg);
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleApprovePengajuan = async () => {
    try {
      await putPengajuanStatus(page_id, {
        status: 'DISETUJUI',
      }, keycloak.token)

      navigate(0);
    } catch (error) {
      console.error(error);
    }
  }

  const handleDeclinePengajuan = async () => {
    try {
      await putPengajuanStatus(page_id, {
        status: 'DITOLAK',
        alasan_penolakan: alasanPenolakan
      }, keycloak.token)

      navigate(0);
    } catch (error) {
      console.error(error);
    }
  }

  const handleCancelPengajuan = async () => {
    try {
      await putPengajuanStatus(page_id, {
        status: 'DIBATALKAN',
        alasan_pembatalan: alasanPembatalan
      }, keycloak.token)

      navigate(0);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (contentData) {
      setApprovalStatus(parseApprovalStatus(contentData.status));
      setUrgency(parseUrgency(contentData.urgensi));
      if (mapRef.current) {
        const coordinates = contentData.array_of_koordinat.map(lonLat => fromLonLat(lonLat));
        drawPolygonAOI(coordinates);
      }
      if (contentData?.Berkas?.jalur_berkas_analisis) {
        getPresignedUrl(contentData?.Berkas?.jalur_berkas_analisis).then((res) => setPresignedHasilAnalisis({
          file: res?.presignedUrl,
          metadata: res?.objectMetadata,
        })).catch(err => console.error(err));
      }
    }
  }, [contentData])

  useEffect(() => {
    getPengajuanDetail(page_id, keycloak.token).then((response) => {
      if (response.data.Pengguna?.id !== keycloak.idTokenParsed.sub && isUser) {
        navigate('/map')
      }

      setContentData(response.data);
    }).catch((err) => console.error(err));
  }, [])

  const drawPolygonAOI = (coordinates) => {
    const vectorSource = new VectorSource();
    const vectorLayer = new VectorLayer({
      id: "polygon-aoi",
      source: vectorSource,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 255, 0.8)",
        }),
      }),
    });
    mapRef.current.addLayer(vectorLayer);
    const aoiFeature = new Feature(new Polygon([coordinates]));
    vectorSource.addFeature(aoiFeature);
    zoomToAOI(aoiFeature.getGeometry().getExtent());
  }

  const zoomToAOI = (extent) => {
    mapRef.current.getView().fit(extent, {
      duration: 350,
      size: [400, 700],
      nearest: true,
    });
  }

  const getPresignedUrl = async (path) => {
    try {
      const res = await getPresignedBerkasByObject(path, keycloak.token);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  const handlePreviewGambar = async () => {
    try {
      const presignedPreviewImageUrl = await getPresignedUrl(contentData?.Berkas?.jalur_gambar_previu);
      const presignedOriginalImageUrl = await getPresignedUrl(contentData?.Berkas?.jalur_gambar);
      const presignedOvrImageUrl = await getPresignedUrl(contentData?.Berkas?.jalur_gambar_ovr);

      setPresignedBerkas({
        previewImage: presignedPreviewImageUrl?.presignedUrl,
        originalImage: presignedOriginalImageUrl?.presignedUrl,
        ovrImage: presignedOvrImageUrl?.presignedUrl
      });

      setPresignedBerkasMetadata({
        previewImage: presignedPreviewImageUrl?.objectMetadata,
        originalImage: presignedOriginalImageUrl?.objectMetadata
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handlePreviewDokumen = async () => {
    openPreviewDokumenModal();
    try {
      const jalurDokumenArray = [];

      for (const jalurDokumen of contentData?.Berkas?.jalur_dokumen) {
        const presignedDokumenUrl = await getPresignedUrl(jalurDokumen);

        jalurDokumenArray.push({
          file: presignedDokumenUrl?.presignedUrl,
          metadata: presignedDokumenUrl?.objectMetadata,
          filename: jalurDokumen.split('/').pop()
        }
        )
      }

      setPresignedDokumen(jalurDokumenArray);
    } catch (error) {
      console.error(error);
    }
  }

  const handlePreviewAnotasi = async () => {
    openPreviewAnotasiModal();
    try {
      const jalurAnotasiArray = [];

      for (const [key, value] of Object.entries(contentData?.Berkas?.jalur_anotasi_gambar)) {
        const presignedAnotasiUrl = await getPresignedUrl(value);

        jalurAnotasiArray.push({
          file: presignedAnotasiUrl?.presignedUrl,
          metadata: presignedAnotasiUrl?.objectMetadata,
          filename: value.split('/').pop(),
          type: key,
        }
        )
      }

      setPresignedBerkasAnotasi(jalurAnotasiArray);
    } catch (error) {
      console.error(error);
    }
  }

  const handlePreviewAttachment = async (path) => {
    openPreviewAttachmentModal();
    try {
      const presignedAttachmentUrl = await getPresignedUrl(path);

      setPresignedAttachment({
        file: presignedAttachmentUrl?.presignedUrl,
        metadata: presignedAttachmentUrl?.objectMetadata
      })
    } catch (error) {
      console.error(error);
    }
  }

  const handleTipeProduk = (tipe) => {
    switch (tipe) {
      case "daytime":
        return "Daytime";
      case "area2x1":
        return "Area 2x1";
      case "burst":
        return "Burst";
      case "nighttime":
        return "Nighttime";
      case "2-frame":
        return "2-Frame Stereo";
      case "5-frame":
        return "5-Frame Stereo";
    }
  }

  const handleChangeInfo = (e) => {
    setInfo(e.target.value);
  }

  const handleUpdateInfo = async () => {
    try {
      await putPengajuanInfo(page_id, {
        info_tambahan: info,
      }, keycloak.token);

      navigate(0);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (approvalStatus === 1) {
      handlePreviewGambar().catch(err => console.error(err));
    }
  }, [approvalStatus])

  const handleConfirmButton = (e, callback) => {
    e.preventDefault();

    handleModalConfirm(callback);
  }

  return (
    <div ref={pdfComponentRef} className="bg-primary-darkerBlue text-white w-full min-h-screen">
      <div className="border-b border-gray-300 flex justify-center pt-4 pr-8 pb-4 bg-primary-darkBlue">
        <div className="w-[95%] flex flex-col">
          <div className="flex">
            <h2 className="mb-1 font-semibold text-white ">Detail Pengajuan</h2>
            <button
              className="ml-2 text-2xl hover:text-hover-lightBlue transition duration-200"
              onClick={downloadScreenshot}
            ><BiScreenshot /></button>
          </div>
          <h3 className="mb-2.5 text-white text-xl">{contentData?.judul_pengajuan}</h3>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-[95%] flex flex-col bg-primary-darkBlue pl-8 pr-8 pt-4 mt-4 mr-20 mb-4 pb-4 ml-5 rounded-md h-max">
          <div className="flex justify-between">
            <div className="w-2/5">
              {(approvalStatus === 1) ?
                <div>
                  <p className="font-bold text-xl"><i>Preview</i> Gambar</p>
                  <img
                    className="w-full h-full object-contain"
                    src={presignedBerkas?.previewImage}
                    alt="Preview Gambar"
                    crossOrigin="true"
                  />
                </div>
                :
                /* MAP */
                <div>
                  <p className="font-bold italic">Area of interest</p>
                  < div id='map' className={`w-full h-screen`}></div>
                </div>
              }
            </div>
            <div className="w-3/5 ml-6">
              <div className="flex items-start w-full">
                <div className="w-[60%]">
                  <h3 className="text-lg font-bold mb-2">Profil Pemohon</h3>
                  <div className="flex items-center">
                    <div>
                      <img src={!contentData?.Pengguna?.jalur_avatar ? '/assets/profile_default.png' : `${process.env.REACT_APP_MINIO_BE_URL}${contentData?.Pengguna?.jalur_avatar}`} className="w-12 h-12 rounded-full ring-2 ring-gray-300 dark:ring-gray-500 object-contain" alt="logo-ad" />
                    </div>
                    <div className="ml-2 pl-2 border-l-4 border-primary-green">
                      <p className="m-0 text-lg font-bold">{contentData?.Pengguna?.unit}</p>
                      <p className="m-0">{contentData?.Pengguna?.instansi}</p>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold mb-1 mt-4">Lokasi</h3>
                    <p className="italic mb-1 text-xs">*format koordinat: lat, lon</p>
                    {contentData?.koordinat_tengah &&
                      <div className="flex">
                        <p className="mb-1 w-1/3">Koordinat Tengah</p>
                        <p className="mb-1 mr-2">:</p>
                        <p className="mb-1">{contentData?.koordinat_tengah[1]}, {contentData?.koordinat_tengah[0]}</p>
                      </div>
                    }
                    {
                      contentData?.array_of_koordinat?.map((koordinat, idx) => {
                        if (idx !== contentData?.array_of_koordinat.length - 1) {
                          return (
                            <div className="flex">
                              <p className="mb-1 w-1/3">Koordinat #{idx + 1}</p>
                              <p className="mb-1 mr-2">:</p>
                              <p className="mb-1">{koordinat[1]}, {koordinat[0]}</p>
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                  {(contentData?.tanggal_awal_pengajuan || contentData?.tanggal_akhir_pengajuan) &&
                    <div className="mt-4">
                      <h3 className="text-lg font-bold mb-1">Rentang Waktu</h3>
                      {contentData?.tanggal_awal_pengajuan &&
                        <div className="flex">
                          <p className="mb-1 w-1/4">Batas Awal</p>
                          <p className="mb-1 mr-2">:</p>
                          <p className="mb-1">{contentData?.tanggal_awal_pengajuan}{contentData?.awal_waktu && `, ${contentData?.awal_waktu}`}</p>
                        </div>
                      }
                      {contentData?.tanggal_akhir_pengajuan &&
                        <div className="flex">
                          <p className="mb-1 w-1/4">Batas Akhir</p>
                          <p className="mb-1 mr-2">:</p>
                          <p className="mb-1">{contentData?.tanggal_akhir_pengajuan}{contentData?.akhir_waktu && `, ${contentData?.akhir_waktu}`}</p>
                        </div>
                      }
                    </div>
                  }
                  <div className="mt-4">
                    <h3 className="text-lg font-bold mb-1">Tipe Citra</h3>
                    <div className="flex">
                      <p className="mb-1 w-1/4">Tipe</p>
                      <p className="mb-1 mr-2">:</p>
                      <p className="mb-1">{contentData?.tipe_produk ? handleTipeProduk(contentData?.tipe_produk) : "Daytime"}</p>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h3 className="text-lg font-bold mb-1">Kualitas Citra</h3>
                    <div className="flex">
                      <p className="mb-1 w-1/4">Nilai</p>
                      <p className="mb-1 mr-2">:</p>
                      <p className="mb-1">{contentData?.cloud_cover}</p>
                    </div>
                  </div>
                  {/* Kabid sees proposal attachment previews */}
                  {isKabid && contentData?.attachments?.length > 0 &&
                    <>
                      <div className="mt-4">
                        <h3 className="text-lg font-bold mb-1">Lampiran</h3>
                        {contentData?.attachments.map((el, idx) => {
                          return (
                            <div className="flex" key={idx}>
                              <span>{idx + 1}.&nbsp;</span><a className="hover:underline hover:cursor-pointer" onClick={() => handlePreviewAttachment(el)}>{el.split('/').pop()}</a>
                            </div>
                          )
                        })}
                      </div>
                      <PreviewAttachmentModal>
                        <div className="flex justify-between h-full">
                          <div className="w-[50%] h-[85%] mr-4">
                            <p className="text-3xl font-bold mb-4"><i>Preview</i> Attachment</p>
                            <object data={presignedAttachment?.file} type="application/pdf" width="100%" height="100%">
                              <p>Alternative text - include a link <a href={presignedAttachment?.file}>to the PDF!</a></p>
                            </object>
                          </div>
                          <div className="w-[50%] ml-4">
                            <p className="text-3xl font-bold mb-4"><i>Metadata</i> Attachment</p>
                            <div className="bg-primary-darkerBlue py-2 px-8 rounded-lg mb-4">
                              <div className="mt-4">
                                <div className="flex">
                                  <p className="mb-1 w-1/4">Ukuran</p>
                                  <p className="mb-1 mr-2">:</p>
                                  <p className="mb-1">{formatBytes(presignedAttachment?.metadata?.size)}</p>
                                </div>
                                <div className="flex">
                                  <p className="mb-1 w-1/4">Jenis</p>
                                  <p className="mb-1 mr-2">:</p>
                                  <p className="mb-1">{presignedAttachment?.metadata?.metaData?.["content-type"]}</p>
                                </div>
                                <div className="flex">
                                  <p className="mb-1 w-1/4">Waktu Modifikasi Terakhir</p>
                                  <p className="mb-1 mr-2">:</p>
                                  <p className="mb-1">{new Date(presignedAttachment?.metadata?.lastModified).toLocaleString('id-ID')}</p>
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between mt-4 mb-4">
                              <button className="flex justify-center items-center bg-primary-green text-2xl hover:bg-hover-green transition duration-300 rounded-sm w-full">
                                <a href={presignedAttachment?.file} target="_blank" download className="mb-0 w-full py-2 px-3 text-white no-underline"><i>Download</i> Attachment</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </PreviewAttachmentModal>
                    </>

                  }
                  {approvalStatus === 1 &&
                    <div className="mt-4">
                      <h3 className="text-lg font-bold mb-1"><i>Metadata</i> Citra</h3>
                      <div className="flex">
                        <p className="mb-1 w-1/4">Ukuran</p>
                        <p className="mb-1 mr-2">:</p>
                        <p className="mb-1">{formatBytes(presignedBerkasMetadata?.originalImage?.size)}</p>
                      </div>
                      <div className="flex">
                        <p className="mb-1 w-1/4">Jenis</p>
                        <p className="mb-1 mr-2">:</p>
                        <p className="mb-1">{presignedBerkasMetadata?.originalImage?.metaData?.["content-type"]}</p>
                      </div>
                      <div className="flex">
                        <p className="mb-1 w-1/4">Waktu Modifikasi Terakhir</p>
                        <p className="mb-1 mr-2">:</p>
                        <p className="mb-1">{new Date(presignedBerkasMetadata?.originalImage?.lastModified).toLocaleString('id-ID')}</p>
                      </div>
                    </div>
                  }
                  {((isUser || isKabid) && approvalStatus === 1 && presignedHasilAnalisis?.file) &&
                    <div className="mt-4">
                      <h3 className="text-lg font-bold mb-1">Berkas Hasil Analisis</h3>
                      <div className="flex">
                        <a href={presignedHasilAnalisis?.file} target="_blank" download className="hover:underline hover:cursor-pointer" >{contentData?.Berkas?.jalur_berkas_analisis?.split('/').pop()}</a>
                      </div>
                    </div>
                  }
                </div>
                <div className="w-[40%]">
                  <div className="flex flex-row items-end flex-wrap">
                    <div className="mr-2 pr-2">
                      <p className="font-bold mb-1">Urgensi</p>
                      {urgency === 0 &&
                        <h2 className={`cursor-default rounded-sm py-1 px-4 bg-primary-green text-base w-fit mb-3`}>{contentData?.urgensi}</h2>
                      }
                      {urgency === 1 &&
                        <h2 className={`cursor-default rounded-sm py-1 px-4 bg-primary-yellow text-base w-fit mb-3`}>{contentData?.urgensi}</h2>
                      }
                      {urgency === 2 &&
                        <h2 className={`cursor-default rounded-sm py-1 px-4 bg-primary-red text-base w-fit mb-3`}>{contentData?.urgensi}</h2>
                      }
                    </div>
                    <div>
                      <p className="font-bold mb-1">Status</p>
                      {approvalStatus === 0 &&
                        <h2 className={`cursor-default rounded-sm py-1 px-4 bg-primary-yellow text-base w-fit mb-3`}>{contentData?.status}</h2>
                      }
                      {approvalStatus === 1 &&
                        <h2 className={`cursor-default rounded-sm py-1 px-4 bg-primary-green text-base w-fit mb-3`}>{contentData?.status}</h2>
                      }
                      {approvalStatus === 3 &&
                        <h2 className={`cursor-default rounded-sm py-1 px-4 bg-primary-lightBlue text-base w-fit mb-3`}>{contentData?.status}</h2>
                      }
                      {(approvalStatus === 2 || approvalStatus === 4) &&
                        <h2 className={`cursor-default rounded-sm py-1 px-5 bg-primary-red text-base w-fit mb-3`}>{contentData?.status}</h2>
                      }
                    </div>
                  </div>
                  <div>
                    <p><span className="font-bold">Catatan</span>: {approvalStatusNotes(contentData?.status, { isUser, isOperator, isKabid })}</p>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <h3 className="text-lg font-bold mb-1">Catatan :</h3>
                <textarea name="alasan-pengajuan" disabled className="w-full h-52 border border-white py-2 px-3 bg-transparent resize-none" value={contentData?.alasan_pengajuan} />
              </div>
              {isOperator &&
                <div className="mt-3">
                  <h3 className="text-lg font-bold mb-1">Informasi Untuk Pemohon :</h3>
                  <textarea name="alasan-pengajuan" onInput={(e) => handleChangeInfo(e)} className="w-full h-52 border border-white py-2 px-3 bg-transparent resize-none" defaultValue={contentData?.info_tambahan ? `${contentData?.info_tambahan}` : ""} />
                  <div className="flex justify-end items-center mt-1" onClick={() => handleUpdateInfo()}>
                    <button type="submit" className="w-fit flex justify-center items-center bg-primary-lightBlue text-base hover:bg-hover-lightBlue transition duration-300 rounded-sm py-1 px-3">
                      <p className="mb-0">Update Informasi</p>
                    </button>
                  </div>
                </div>
              }
              {/* Operator uploads sattelite imagery */}
              {(isOperator && approvalStatus === 3 && approvalState === 1) &&
                <form onSubmit={(e) => handleConfirmButton(e, handleUpload)}>
                  <div className="mt-4">
                    <h3 className="text-lg font-bold mb-2">Pilih Tanggal Citra</h3>
                    <div className="flex items-center mb-4">
                      <p className="mb-0 w-1/4">Tanggal Citra</p>
                      <p className="mb-0 mr-2">:</p>
                      <input className="text-black" type="datetime-local" onChange={(e) => setTanggalCitra(e.target.value)} required />
                    </div>
                    <h3 className="text-lg font-bold mb-2">Unggah file citra</h3>
                    <div className="flex items-center">
                      <p className="mb-0 w-1/4">Citra Satelit<sup>1</sup></p>
                      <p className="mb-0 mr-2">:</p>
                      <input type="file" accept=".tif, .tiff" onChange={(e) => handleOnFileChange(e, setBerkasImage)} required />
                    </div>
                    <h3 className="text-lg font-bold mt-4 mb-2">Unggah file berkas</h3>
                    <div className="flex items-center mt-2">
                      <p className="mb-0 w-1/4">Berkas Pelengkap<sup>2</sup> (opsional)</p>
                      <p className="mb-0 mr-2">:</p>
                      <input type="file" accept="application/*" onChange={(e) => handleOnMultipleFileChange(e, setBerkasTambahanList)} multiple />
                    </div>
                    <div className="flex items-center mt-2">
                      <p className="mb-0 w-1/4">Berkas Anotasi - Aircraft<sup>3</sup> (opsional)</p>
                      <p className="mb-0 mr-2">:</p>
                      <input type="file" accept=".zip" onChange={(e) => handleOnFileChange(e, setBerkasAnotasiAircraft)} />
                    </div>
                    <div className="flex items-center mt-2">
                      <p className="mb-0 w-1/4">Berkas Anotasi - Vessel<sup>3</sup> (opsional)</p>
                      <p className="mb-0 mr-2">:</p>
                      <input type="file" accept=".zip" onChange={(e) => handleOnFileChange(e, setBerkasAnotasiVessel)} />
                    </div>
                    <div className="flex items-center mt-2">
                      <p className="mb-0 w-1/4">Berkas Anotasi - Building<sup>3</sup> (opsional)</p>
                      <p className="mb-0 mr-2">:</p>
                      <input type="file" accept=".zip" onChange={(e) => handleOnFileChange(e, setBerkasAnotasiBuilding)} />
                    </div>
                    {uploadStatus !== '' &&
                      <div className="flex items-center mt-2">
                        <p className="mr-2 mb-0">{uploadStatus}</p>
                        <HashLoader
                          color='#4c9ae7'
                          size={20}
                          aria-label="Loading Spinner"
                        />
                      </div>
                    }
                  </div>
                  <div className="mt-4">
                    <p className="font-bold text-sm mb-0"><i>1. Gambar dalam format .tif atau .tiff </i></p>
                    <p className="font-bold text-sm mb-0"><i>2. Berkas tidak boleh memuat gambar </i></p>
                    <p className="font-bold text-sm mb-0"><i>3. Berkas dalam format .zip</i></p>
                  </div>
                  <div className="mt-4 flex justify-between">
                    <div className="flex mt-4 w-[39%]">
                    </div>
                    <div className="mt-4 flex justify-end">
                      <div className="flex mr-4 items-center">
                        <p className="mb-0 text-primary-red">{fileValidationMsg}</p>
                      </div>
                      <button type="submit" className="w-fit flex justify-center items-center bg-primary-green text-2xl hover:bg-hover-green transition duration-300 rounded-sm py-2 px-3">
                        <p className="mb-0">Upload</p>
                      </button>
                      <button className="ml-3 w-fit flex justify-center items-center bg-primary-gray text-2xl hover:bg-hover-gray transition duration-300 rounded-sm py-2 px-3">
                        <p className="mb-0" onClick={() => setApprovalState(0)}>Kembali</p>
                      </button>
                    </div>
                  </div>
                </form>
              }
              {/* Kabid rejects a proposal */}
              {(isKabid && approvalStatus === 0 && approvalState === 2) &&
                <div className="mt-2">
                  <h3 className="text-lg font-bold mb-1">Alasan Penolakan :</h3>
                  <textarea
                    name="alasan-pengajuan"
                    className="w-full h-52 border border-white py-2 px-3 bg-primary-blue bg-opacity-20 resize-none hover:bg-opacity-80 focus:bg-opacity-80 focus:outline-none transition duration-300"
                    required
                    onChange={(e) => setAlasanPenolakan(e.target.value)}
                    value={alasanPenolakan}
                  />
                  <div className="mt-4 flex justify-end">
                    <button className="w-fit flex justify-center items-center bg-primary-red p-1 text-2xl hover:bg-hover-red transition duration-300 rounded-sm py-2 px-3">
                      <p className="mb-0" onClick={(e) => handleConfirmButton(e, handleDeclinePengajuan)}>Tolak</p>
                    </button>
                    <button className="ml-3 w-fit flex justify-center items-center bg-primary-gray p-1 text-2xl hover:bg-hover-gray transition duration-300 rounded-sm py-2 px-3">
                      <p className="mb-0" onClick={() => setApprovalState(0)}>Kembali</p>
                    </button>
                  </div>
                </div>
              }
              {/* Operator cancels a proposal */}
              {(isOperator && approvalStatus === 3 && approvalState === 2) &&
                <div className="mt-2">
                  <h3 className="text-lg font-bold mb-1">Alasan Pembatalan :</h3>
                  <textarea
                    name="alasan-pengajuan"
                    className="w-full h-52 border border-white py-2 px-3 bg-primary-blue bg-opacity-20 resize-none hover:bg-opacity-80 focus:bg-opacity-80 focus:outline-none transition duration-300"
                    required
                    onChange={(e) => setAlasanPembatalan(e.target.value)}
                    value={alasanPembatalan}
                  />
                  <div className="mt-4 flex justify-end">
                    <button
                      className="w-fit flex justify-center items-center bg-primary-red p-1 text-2xl hover:bg-hover-red transition duration-300 rounded-sm py-2 px-3"
                      onClick={(e) => handleConfirmButton(e, handleCancelPengajuan)}
                    >
                      <p className="mb-0" >Batalkan</p>
                    </button>
                    <button
                      className="ml-3 w-fit flex justify-center items-center bg-primary-gray p-1 text-2xl hover:bg-hover-gray transition duration-300 rounded-sm py-2 px-3"
                      onClick={() => setApprovalState(0)}
                    >
                      <p className="mb-0">Kembali</p>
                    </button>
                  </div>
                </div>
              }
              {/* USER REJECTED */}
              {(approvalStatus === 2) &&
                <div className="mt-4">
                  <h3 className="text-lg font-bold mb-1">Alasan Penolakan :</h3>
                  <textarea name="alasan-penolakan" disabled className="w-full h-52 border border-white py-2 px-3 bg-transparent resize-none" value={contentData?.alasan_penolakan} />
                </div>
              }
              {/* USER CANCELLED */}
              {(approvalStatus === 4) &&
                <div className="mt-4">
                  <h3 className="text-lg font-bold mb-1">Alasan Pembatalan :</h3>
                  <textarea name="alasan-penolakan" disabled className="w-full h-52 border border-white py-2 px-3 bg-transparent resize-none" value={contentData?.alasan_pembatalan} />
                </div>
              }
              {/* USER APPROVED */}
              {(approvalStatus === 1 && !isOperator) &&
                <div className="flex flex-col mt-4">
                  <div className="flex flex-col justify-between">
                    <>
                      <button className="flex justify-center items-center bg-primary-green mb-4 text-2xl hover:bg-hover-green transition duration-300 rounded-sm">
                        <a href={presignedBerkas?.originalImage} target="_blank" download className="mb-0 w-full p-1 py-2 px-3 text-white no-underline"><i>Download</i> Gambar Asli</a>
                      </button>
                    </>
                    {contentData?.Berkas?.jalur_dokumen.length > 0 &&
                      <button onClick={handlePreviewDokumen} className="flex justify-center items-center bg-primary-lightBlue mb-4 p-1 text-2xl w-full hover:bg-hover-lightBlue transition duration-300 rounded-sm py-2 px-3">
                        <p className="mb-0">Lihat Daftar Berkas Tambahan</p>
                      </button>
                    }
                    {contentData?.Berkas?.jalur_anotasi_gambar &&
                      <button onClick={handlePreviewAnotasi} className="flex justify-center items-center bg-primary-lightBlue mb-4 p-1 text-2xl w-full hover:bg-hover-lightBlue transition duration-300 rounded-sm py-2 px-3">
                        <p className="mb-0">Lihat Daftar Berkas Anotasi</p>
                      </button>
                    }
                  </div>
                  <PreviewAnotasiModal>
                    <div className="flex justify-between h-full">
                      <div className="w-full h-[90%] overflow-y-scroll">
                        <p className="text-3xl font-bold mb-4"><i>Metadata</i> Berkas</p>
                        <div className="flex flex-wrap justify-center">
                          {presignedBerkasAnotasi.length > 0 && presignedBerkasAnotasi.map((el, idx) => {
                            return (
                              <div className="bg-primary-darkerBlue py-2 px-8 rounded-lg w-[30%] mr-4 mb-4">
                                <div className="mt-4">
                                  <p className="font-bold text-xl">{el?.type.charAt(0).toUpperCase() + el?.type.slice(1)}</p>
                                  <div className="flex">
                                    <p className="mb-1 w-1/4">Nama File</p>
                                    <p className="mb-1 mr-2">:</p>
                                    <p className="mb-1">{el?.filename}</p>
                                  </div>
                                  <div className="flex">
                                    <p className="mb-1 w-1/4">Ukuran</p>
                                    <p className="mb-1 mr-2">:</p>
                                    <p className="mb-1">{formatBytes(el?.metadata?.size)}</p>
                                  </div>
                                  <div className="flex">
                                    <p className="mb-1 w-1/4">Jenis</p>
                                    <p className="mb-1 mr-2">:</p>
                                    <p className="mb-1">{el?.metadata?.metaData?.["content-type"]}</p>
                                  </div>
                                  <div className="flex">
                                    <p className="mb-1 w-1/4">Waktu Modifikasi Terakhir</p>
                                    <p className="mb-1 mr-2">:</p>
                                    <p className="mb-1">{new Date(el?.metadata?.lastModified).toLocaleString('id-ID')}</p>
                                  </div>
                                </div>
                                <div className="flex justify-between mt-4 mb-4 mr-4">
                                  <button className="flex justify-center items-center bg-primary-green text-2xl hover:bg-hover-green transition duration-300 rounded-sm w-full">
                                    <a href={el?.file} target="_blank" download className="mb-0 te w-full py-2 px-3 text-white no-underline"><i>Download</i> Berkas Anotasi</a>
                                  </button>
                                </div>
                              </div>
                            )
                          })
                          }
                        </div>
                      </div>
                    </div>
                  </PreviewAnotasiModal>
                  <PreviewDokumenModal>
                    <div className="flex justify-between h-full">
                      <div className="w-[50%] h-[85%] mr-4">
                        <p className="text-3xl font-bold mb-4"><i>Preview</i> Berkas</p>
                        <object data={presignedDokumen[berkasPreviewIdx]?.file} width="100%" height="100%">
                          <p>Berkas ini tidak dapat di-<i>preview</i></p>
                        </object>
                      </div>
                      <div className="w-[50%] h-[95%] ml-4 overflow-y-scroll">
                        <p className="text-3xl font-bold mb-4"><i>Metadata</i> Berkas</p>
                        {presignedDokumen.length > 0 && presignedDokumen.map((el, idx) => {
                          return (
                            <div className="bg-primary-darkerBlue py-2 px-8 rounded-lg mb-4">
                              <div className="mt-4">
                                <div className="flex">
                                  <p className="mb-1 w-1/4">Nama File</p>
                                  <p className="mb-1 mr-2">:</p>
                                  <p className="mb-1">{el?.filename}</p>
                                </div>
                                <div className="flex">
                                  <p className="mb-1 w-1/4">Ukuran</p>
                                  <p className="mb-1 mr-2">:</p>
                                  <p className="mb-1">{formatBytes(el?.metadata?.size)}</p>
                                </div>
                                <div className="flex">
                                  <p className="mb-1 w-1/4">Jenis</p>
                                  <p className="mb-1 mr-2">:</p>
                                  <p className="mb-1">{el?.metadata?.metaData?.["content-type"]}</p>
                                </div>
                                <div className="flex">
                                  <p className="mb-1 w-1/4">Waktu Modifikasi Terakhir</p>
                                  <p className="mb-1 mr-2">:</p>
                                  <p className="mb-1">{new Date(el?.metadata?.lastModified).toLocaleString('id-ID')}</p>
                                </div>
                              </div>
                              <div className="flex justify-between mt-4 mb-4 mr-4">
                                <button onClick={() => setBerkasPreviewIdx(idx)} className="flex justify-center items-center bg-primary-lightBlue text-2xl hover:bg-hover-lightBlue transition duration-300 rounded-sm w-full mr-4">
                                  <a className="mb-0 te w-full py-2 px-3 text-white no-underline"><i>Preview</i> Berkas</a>
                                </button>
                                <button className="flex justify-center items-center bg-primary-green text-2xl hover:bg-hover-green transition duration-300 rounded-sm w-full">
                                  <a href={el?.file} target="_blank" download className="mb-0 te w-full py-2 px-3 text-white no-underline"><i>Download</i> Berkas</a>
                                </button>
                              </div>
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>
                  </PreviewDokumenModal>
                </div>
              }

              {/* Operator uploads sattelite imagery */}
              {(isUser && approvalStatus === 1 && approvalState === 1) &&
                <form onSubmit={(e) => handleConfirmButton(e, handleUploadHasilAnalisis)}>
                  <div className="mt-2">
                    <h3 className="text-lg font-bold mb-2">Unggah berkas hasil analisis</h3>
                    <div className="flex items-center">
                      <p className="mb-0 w-1/4">Berkas Hasil Analisis<sup>1</sup></p>
                      <p className="mb-0 mr-2">:</p>
                      <input type="file" accept=".kml, .kmz" onChange={(e) => handleOnFileChange(e, setBerkasHasilAnalisis)} required />
                    </div>
                    {uploadStatus !== '' &&
                      <div className="flex items-center mt-2">
                        <p className="mr-2 mb-0">{uploadStatus}</p>
                        <HashLoader
                          color='#4c9ae7'
                          size={20}
                          aria-label="Loading Spinner"
                        />
                      </div>
                    }
                  </div>
                  <div className="mt-4">
                    <p className="font-bold text-sm mb-0"><i>1. Berkas dalam format .kml atau .kmz </i></p>
                  </div>
                  <div className="mt-4 flex justify-between">
                    <div className="flex mt-4 w-[39%]">
                    </div>
                    <div className="flex justify-end">
                      <div className="flex mr-4 items-center">
                        <p className="mb-0 text-primary-red">{fileValidationMsg}</p>
                      </div>
                      <button type="submit" className="w-fit flex justify-center items-center bg-primary-green text-2xl hover:bg-hover-green transition duration-300 rounded-sm py-2 px-3">
                        <p className="mb-0">Upload</p>
                      </button>
                      <button className="ml-3 w-fit flex justify-center items-center bg-primary-gray text-2xl hover:bg-hover-gray transition duration-300 rounded-sm py-2 px-3">
                        <p className="mb-0" onClick={() => setApprovalState(0)}>Kembali</p>
                      </button>
                    </div>
                  </div>
                </form>
              }

              {/* User uploads analysis result file */}
              {(isUser && approvalStatus === 1 && approvalState === 0) &&
                <div className="w-full flex">
                  <button onClick={() => setApprovalState(1)} className="flex justify-center items-center bg-primary-lightBlue p-1 text-2xl w-full hover:bg-hover-lightBlue transition duration-300 rounded-sm py-2 px-3">
                    <p className="mb-0"><i>Upload</i> Berkas Hasil Analisis</p>
                  </button>
                </div>
              }
            </div>
          </div>
          {/* Kabid accepts a proposal */}
          {(isKabid && approvalStatus === 0 && approvalState === 0) &&
            <div className="w-full flex justify-end mt-4 w-[39%]">
              <div className="flex w-[39%]">
                <button
                  className="flex justify-center items-center bg-primary-green p-1 text-2xl w-full hover:bg-hover-green transition duration-300 rounded-sm py-2 px-3"
                  onClick={(e) => handleConfirmButton(e, handleApprovePengajuan)}
                >
                  <p className="mb-0">Setujui</p>
                </button>
                <button
                  className="ml-3 flex justify-center items-center bg-primary-red p-1 text-2xl w-full hover:bg-hover-red transition duration-300 rounded-sm py-2 px-3"
                  onClick={() => setApprovalState(2)}
                >
                  <p className="mb-0">Tolak</p>
                </button>

              </div>
            </div>
          }
          {/* Operator choosing either upload/cancel a proposal */}
          {(isOperator && approvalStatus === 3 && approvalState === 0) &&
            <div className="w-full flex justify-end mt-4 w-[39%]">
              <div className="flex w-[39%]">
                <button
                  className="flex justify-center items-center bg-primary-green p-1 text-2xl w-full hover:bg-hover-green transition duration-300 rounded-sm py-2 px-3"
                  onClick={() => setApprovalState(1)}
                >
                  <p className="mb-0">Upload</p>
                </button>
                <button
                  className="ml-3 flex justify-center items-center bg-primary-red p-1 text-2xl w-full hover:bg-hover-red transition duration-300 rounded-sm py-2 px-3"
                  onClick={() => setApprovalState(2)}
                >
                  <p className="mb-0">Batalkan</p>
                </button>

              </div>
            </div>
          }

          {/*  Universal confirmation modal for all actions */}
          <ConfirmationModal />

        </div>
      </div>
    </div >
  )
}

export default UserApprovalDetail;