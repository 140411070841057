import React from 'react';
import { createRoot } from 'react-dom/client';
import { useEffect, useState, useRef } from 'react';
import Map from 'ol/Map.js';
import OSM from 'ol/source/OSM.js';
import XYZ from 'ol/source/XYZ.js';
import { Tile as TileLayer } from 'ol/layer.js';
import View from 'ol/View.js';
import { Vector as VectorSource } from 'ol/source.js';
import { fromLonLat } from "ol/proj";
import { defaults, Zoom } from 'ol/control';

import { FiPlus, FiMinus } from "react-icons/fi";
import { FaLocationDot, FaRegRectangleXmark } from "react-icons/fa6";
import { TbDeviceIpadPlus, TbCirclePlus2 } from "react-icons/tb";
import { FaDrawPolygon } from "react-icons/fa";
import { MdDraw } from "react-icons/md";
import { FaMap } from "react-icons/fa";

import ShowCoordinates from './Components/MapTools/ShowCoordinates';
import LayerList from './Components/LayerList';
import DrawShape from './Components/MapTools/DrawShape';
import DrawControls from './Components/MapTools/DrawControls';
import LocationMarker from './Components/MapTools/LocationMarker';
import SearchBar from './Components/SearchBar';
import Sidebar from '../../Components/Sidebar';


const MainMap = () => {
  const mapRef = useRef(null);
  const [, forceUpdate] = React.useState();
  const [isShowLayerList, setIsShowLayerList] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState('osm');
  const [isMarker, setIsMarker] = useState(false);
  const [vectorShape, setVectorShape] = useState();
  const [isShape, setIsShape] = useState(false);
  const [shapeType, setShapeType] = useState('rectangle');
  const [shapeBtnOrder, setShapeBtnOrder] = useState(['rectangle', 'circle', 'polygon']);
  const [shapeLayer, setShapeLayer] = useState();
  const [pointLayer, setPointLayer] = useState();
  const [drawingLayer, setDrawingLayer] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [polygonCoordinates, setPolygonCoordinates] = useState([["", ""], ["", ""], ["", ""], ["", ""], ["", ""]]);
  const [markerCoordinates, setMarkerCoordinates] = useState([["", ""]]);

  const vectorSource = new VectorSource({ wrapX: false });
  const osmLayer = new TileLayer({
    source: new OSM()
  })
  const darkLayer = new TileLayer({
    source: new XYZ({
      url: 'https://s.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
      maxZoom: 19
    })
  })
  const satelliteLayer = new TileLayer({
    source: new XYZ({
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      maxZoom: 19
    })
  })

  const worldExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
  const initializeMap = () => {
    const map = new Map({
      target: 'map',
      layers: [osmLayer],
      view: new View({
        center: fromLonLat([118.8186111, -1.15]),
        zoom: 5.34,
        extent: worldExtent
      }),
      controls: defaults({ attribution: false })
    });
    mapRef.current = map;

    return map;
  }

  useEffect(() => {
    initializeMap();
  }, []);

  useEffect(() => {
    // ZOOM CONTROL BUTTONS STYLING
    const zoomButtons = document.querySelector('.ol-zoom');
    zoomButtons?.classList.add('bg-transparent', 'top-[115px]', 'left-4');

    const zoomControl = mapRef.current.getControls().getArray().find(control => control instanceof Zoom);
    if (zoomControl) {
      const zoomInButton = zoomControl.element.querySelector('.ol-zoom-in');
      const zoomOutButton = zoomControl.element.querySelector('.ol-zoom-out');

      if (zoomInButton && zoomOutButton) {
        zoomInButton.innerHTML = '';
        zoomOutButton.innerHTML = '';
        const zoomInIconContainer = document.createElement('div');
        const zoomOutIconContainer = document.createElement('div');
        const zoomInRoot = createRoot(zoomInIconContainer);
        const zoomOutRoot = createRoot(zoomOutIconContainer);
        zoomInRoot.render(<FiPlus />);
        zoomOutRoot.render(<FiMinus />);
        zoomInButton.appendChild(zoomInIconContainer);
        zoomOutButton.appendChild(zoomOutIconContainer);

        const styleClass = ['bg-primary-purple', 'hover:bg-opacity-85', 'hover:border-none', 'transition', 'duration-300', 'text-white', 'h-[45px]', 'w-[45px]', 'bg-opacity-70', 'text-3xl', 'flex', 'justify-center', 'items-center']
        zoomInButton.classList.add(...styleClass, 'mb-1');
        zoomOutButton.classList.add(...styleClass);
      }
    }
    forceUpdate({})
  }, [mapRef.current]);

  useEffect(() => {
    handleChangeLayer();
  }, [selectedLayer]);

  const getVectorLayer = (id) => {
    if (mapRef.current) {
      let vectorLayer;
      mapRef.current.getLayers().forEach(layer => {
        if (layer.get('id') === id) {
          vectorLayer = layer
        }
      });
      return vectorLayer;
    }
  }

  const handleChangeLayer = () => {
    const shapeLayer = getVectorLayer('shape-vector');
    const pointLayer = getVectorLayer('point-vector');
    const drawingLayer = getVectorLayer('drawing-vector');
    const addAvailableLayers = () => {
      if (shapeLayer) {
        mapRef.current.addLayer(shapeLayer);
      }
      if (pointLayer) {
        mapRef.current.addLayer(pointLayer);
      }
      if (drawingLayer) {
        mapRef.current.addLayer(drawingLayer);
      }
    }

    mapRef.current.getLayers().clear();
    switch (selectedLayer) {
      case 'osm':
        mapRef.current.addLayer(osmLayer);
        addAvailableLayers();
        break;
      case 'satellite':
        mapRef.current.addLayer(satelliteLayer);
        addAvailableLayers();
        break;
      case 'dark':
        mapRef.current.addLayer(darkLayer);
        addAvailableLayers();
        break;
    }
    setIsShowLayerList(false);
  }

  const deleteVectorLayer = (id, isShape) => {
    mapRef.current.removeLayer(getVectorLayer(id));
    if (isShape) {
      deleteOverlay('area-overlay');
      setShapeLayer();
      setPolygonCoordinates([["", ""], ["", ""], ["", ""], ["", ""], ["", ""]]);
    } else {
      deleteOverlay('coordinates-overlay')
      setPointLayer();
      toogleMarker();
    }
  }

  const deleteOverlay = (id) => {
    const overlayClassName = 'bg-primary-purple text-white p-2 rounded-sm bg-opacity-85 bottom-[5%] left-[45%] block select-text';
    if (document.getElementById(id)) {
      document.getElementById(id).remove();
    }
    const elementsToRemove = document.getElementsByClassName(overlayClassName);
    if (elementsToRemove) {
      const elementsArray = Array.from(elementsToRemove);
      elementsArray.forEach(element => {
        element.remove();
      });
    }
  }

  const toogleShape = (clickedShape) => {
    swapShapeButton(clickedShape);
    if (isShape === false || shapeType !== clickedShape) {
      setIsShape(true);
      setShapeType(clickedShape);
      setIsMarker(false);
      setIsDrawing(false);
    } else {
      setIsShape(false);
      mapRef.current.removeInteraction(vectorShape);
    }
  }

  const toogleMarker = () => {
    if (isMarker) {
      setIsMarker(false);
      setIsDrawing(false);
    } else {
      setIsMarker(true);
      setIsShape(false);
      mapRef.current.removeInteraction(vectorShape);
    }
  }

  const handleToggleDrawing = () => {
    if (isDrawing) {
      setIsDrawing(false);
    } else {
      setIsDrawing(true);
      setIsShape(false);
      setIsMarker(false);
      mapRef.current.removeInteraction(vectorShape);
    }
  };

  const handleDeleteDrawing = () => {
    if (drawingLayer) {
      const source = drawingLayer.getSource();
      source.clear();
    }
  };

  const swapShapeButton = (selectedShape) => {
    if (selectedShape !== shapeType) {
      switch (selectedShape) {
        case 'rectangle':
          setShapeBtnOrder(['rectangle', 'circle', 'polygon']);
          break;
        case 'circle':
          setShapeBtnOrder(['circle', 'rectangle', 'polygon']);
          break;
        case 'polygon':
          setShapeBtnOrder(['polygon', 'rectangle', 'circle']);
          break;
      }
    }
  }

  const buttonStyle = 'bg-primary-purple hover:bg-opacity-85 transition duration-300 cursor-pointer rounded-sm bg-opacity-70 w-full h-12 flex justify-center items-center text-2xl';
  return (
    <div>
      {/* MAP */}
      <div id='map' className={`absolute top-0 bottom-0 w-full ${isShape === false ? '' : 'cursor-crosshair'} ${isMarker === false ? '' : 'cursor-copy'}`}>
        <div id='coordinates-display' className='absolute z-50 bottom-2 left-2 px-3 py-1 bg-primary-purple bg-opacity-70 text-white rounded'></div>
      </div>

      {/* MAP TOOLS*/}
      <div className='absolute top-[16px] bottom-auto left-4 w-[45px]' onClick={() => forceUpdate({})}>
        <SearchBar map={mapRef.current} deleteOverlay={deleteOverlay} setIsMarker={setIsMarker} />
      </div>
      <div className='absolute top-[244px] bottom-auto left-4 w-[45px] h-[45px] text-white'>
        {/* MARKER TOOL */}
        <div className='flex hidden'>
          <div
            id={`${isMarker ? 'marker-btn-active' : 'marker-btn-inactive'}`}
            className={`${buttonStyle} mb-1 ${isMarker ? 'bg-opacity-80 text-blue-400' : ''}`}
            title='Aktifkan Pemilihan Lokasi'
            onClick={() => toogleMarker()}
          >
            <FaLocationDot className='w-[45px]' />
          </div>
          <div className={`${buttonStyle} text-xl ml-1 ${pointLayer ? 'block' : 'hidden'}`} title='Hapus Lokasi' disabled={pointLayer ? false : true} onClick={() => deleteVectorLayer('point-vector', false)}>
            <FaRegRectangleXmark className='w-[45px]' />
          </div>
        </div>
        {/* SHAPE TOOLS */}
        <div className='flex hidden'>
          <div className='group flex mb-1'>
            {shapeBtnOrder.map((shape, idx) => (
              <div key={`${shape}btn-${idx}`} id={`${isShape ? 'aoi-btn-active' : 'aoi-btn-inactive'}`} className={`${buttonStyle} ${(isShape === true && idx === 0) ? 'bg-opacity-80 text-blue-400' : ''} ${idx !== 0 ? 'hidden' : ''}`}
                title={`Gambar AOI ${shape}`}
                onClick={() => toogleShape(shape)}>
                {shape === 'rectangle' ?
                  <TbDeviceIpadPlus className={`w-[45px] ${idx !== 0 ? 'mt-[12px]' : ''}`} />
                  : shape === 'circle' ? <TbCirclePlus2 className={`w-[45px] ${idx !== 0 ? 'mt-[12px]' : ''}`} />
                    : shape === 'polygon' ? <FaDrawPolygon className={`w-[45px] ${idx !== 0 ? 'mt-[12px]' : ''}`} /> : ''
                }
              </div>
            ))}
          </div>
          <div className={`${buttonStyle} text-xl ml-1 ${shapeLayer ? 'block' : 'hidden'}`}
            title='Hapus AOI' disabled={shapeLayer ? false : true}
            onClick={() => deleteVectorLayer('shape-vector', true)}>
            <FaRegRectangleXmark className='w-[45px]' />
          </div>
        </div>

        {/* DRAWING TOOL */}
        {/* <div className='flex'>
          <div className={`${buttonStyle} mb-1 ${isDrawing ? 'bg-opacity-80 text-blue-400' : ''}`} title='Gambar Bebas' onClick={handleToggleDrawing}>
            <MdDraw className='w-[45px]' />
          </div>
          <div className={`${buttonStyle} text-xl ml-1 ${isDrawing && drawingLayer ? 'block' : 'hidden'}`} title='Hapus Gambar' disabled={shapeLayer ? false : true} onClick={handleDeleteDrawing}>
            <FaRegRectangleXmark className='w-[45px]' />
          </div>
        </div> */}

        <div className={`${buttonStyle}`} title='Daftar layer' onClick={() => setIsShowLayerList(!isShowLayerList)}>
          <FaMap />
        </div>
      </div>

      {/* COMPONENTS */}
      <DrawControls map={mapRef.current} setDrawingLayer={setDrawingLayer} active={isDrawing} />

      {isShowLayerList &&
        <LayerList setSelectedLayer={setSelectedLayer} />
      }
      {isShape &&
        <DrawShape
          map={mapRef.current}
          shapeType={shapeType}
          setIsShape={setIsShape}
          vectorSource={vectorSource}
          vectorShape={vectorShape}
          setVectorShape={setVectorShape}
          setShapeLayer={setShapeLayer}
          setPolygonCoordinates={setPolygonCoordinates}
        />
      }
      {isMarker &&
        <LocationMarker
          map={mapRef.current}
          vectorSource={vectorSource}
          setPointLayer={setPointLayer}
          setMarkerCoordinates={setMarkerCoordinates}
        />
      }
      <ShowCoordinates map={mapRef.current} />
      <Sidebar map={mapRef.current} isMarker={isMarker} isShape={isShape} toogleShape={toogleShape} toogleMarker={toogleMarker} polygonCoordinates={polygonCoordinates} setPolygonCoordinates={setPolygonCoordinates} markerCoordinates={markerCoordinates} setMarkerCoordinates={setMarkerCoordinates} />
    </div >
  );
};

export default MainMap;