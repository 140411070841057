import axios from "axios";

const BASE_URL = process.env.REACT_APP_BE_URL;

const createNewProfile = async (token, unit) => {
    try {
        return await axios.post(`${BASE_URL}/profil/by-token`, {
            instansi: '',
            unit: unit,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    } catch (error) {
        throw error;
    }
}

const updateProfile = async (data, token) => {
    try {
        return await axios.post(`${BASE_URL}/profil/by-token`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    } catch (error) {
        throw error;
    }
}

const getProfileById = async (id, token) => {
    try {
        return await axios.get(`${BASE_URL}/profil/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    } catch (error) {
        throw error;

    }
}

const getAllProfile = async (token) => {
    try {
        return await axios.get(`${BASE_URL}/profil`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    } catch (error) {
        throw error;
    }
}

const changeProfileAvatar = async (id, formData, token) => {
    try {
        return await axios.post(`${BASE_URL}/profil/avatar/change/${id}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    } catch (error) {
        throw error;
    }
}

export {
    getProfileById,
    getAllProfile,
    createNewProfile,
    updateProfile,
    changeProfileAvatar
}