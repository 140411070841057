import React, { useEffect, useState } from 'react';
import { Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';
import { Draw, Modify } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Circle, LineString } from 'ol/geom';

const DrawControls = ({ map, setDrawingLayer, active, setActive }) => {
  const [draw, setDraw] = useState(null);
  const [modify, setModify] = useState(null);

  useEffect(() => {
    initializeDrawingLayer();
  }, [map]);

  const initializeDrawingLayer = () => {
    if (map) {
      const drawingLayer = new VectorLayer({
        source: new VectorSource(),
        id: 'drawing-vector',
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: '#ffcc33',
            }),
          }),
        }),
      });

      setDrawingLayer(drawingLayer);
      map.addLayer(drawingLayer);

      const modifyInteraction = new Modify({ source: drawingLayer.getSource() });
      setModify(modifyInteraction);
      map.addInteraction(modifyInteraction);

      const drawInteraction = new Draw({
        source: drawingLayer.getSource(),
        type: 'LineString',
        freehand: true,
      });

      setDraw(drawInteraction);
      map.addInteraction(drawInteraction);

      if (drawInteraction) {
        drawInteraction.on('drawend', handleDrawEnd);
      }
    }
  };

  const handleDrawEnd = (event) => {
    if (draw) {
      draw.setActive(false);
      modify.setActive(false);

      if (active) {
        draw.setActive(true);
        modify.setActive(true);
      }

      const feature = event.feature;
      if (feature) {
        const source = draw.getSource();
        source.removeFeature(feature);
      }
    }
  };

  useEffect(() => {
    if (draw) {
      draw.setActive(active);
      modify.setActive(active);
    }

    return () => {
      if (draw) {
        draw.un('drawend', handleDrawEnd);
      }
    };
  }, [draw, modify, active]);

  return null;
};

export default DrawControls;