import { useState, useEffect } from "react";
import { changeProfileAvatar, updateProfile } from "../../../Services/pengguna";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../Context/UserContext";

export default function Profile() {
  const [profileData, setProfileData] = useState({
    instansi: '',
    unit: '',
    jalur_avatar: ''
  });
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const userContextData = useUserContext();

  const [isMenuPassword, setIsMenuPassword] = useState(false);

  const handleChangeInputData = (newData) => {
    setProfileData((prev) => {
      return {
        ...prev,
        ...newData
      }
    })
  }

  const handleSubmitProfile = async (e) => {
    e.preventDefault();
    try {
      await updateProfile(profileData, keycloak.token);

      navigate(0);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChangeAvatar = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];

    formData.append('file', file)

    try {
      await changeProfileAvatar(keycloak.idTokenParsed?.sub, formData, keycloak.token);
      navigate(0);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setProfileData(userContextData);
  }, [userContextData])

  return (
    <div className="bg-primary-darkerBlue min-h-screen">
      <div className="border-b border-gray-300 flex justify-center pt-4 bg-primary-darkBlue">
        <div className="w-[85%] flex flex-col">
          <h2 className="mb-4 font-semibold leading-7 text-white ">Profil Pengguna</h2>
          <div className=" ">
            <button onClick={() => setIsMenuPassword(false)} className={`mt-1 text-white rounded-t-lg p-2 ${isMenuPassword === false ? 'border-b-4 border-primary-lightBlue bg-primary-blue font-bold' : ''}`}>Data Profile</button>
            <button onClick={() => keycloak.login({ action: "UPDATE_PASSWORD" })} className={`text-white ml-3 rounded-t-lg p-2 ${isMenuPassword === true ? 'border-b-4 border-primary-lightBlue bg-primary-blue font-bold' : ''} `}>Ganti Password</button>
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-4">
        <div className="w-[85%] flex flex-col bg-primary-darkBlue p-8 rounded-md h-max">
          <h4 className="text-md text-white font-medium">Informasi Akun</h4>
          <form onSubmit={(e) => handleSubmitProfile(e)}>
            <div className="flex flex-1 w-full justify-start gap-60 mb-3" >
              <div className="pb-4 w-50">
                <div className="sm:col-span-3 mb-2">
                  <label className="block text-sm text-white font-medium leading-6">Username</label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="username"
                      id="username"
                      value={keycloak?.idTokenParsed?.preferred_username}
                      className="block w-full pl-2 rounded-sm py-1.5"
                      disabled
                    />
                  </div>
                </div>
                <div className="sm:col-span-3 mb-2">
                  <label className="block text-sm text-white font-medium leading-6">Email</label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={keycloak?.idTokenParsed?.email}
                      className="block w-full pl-2 rounded-sm py-1.5"
                      disabled
                    />
                  </div>
                </div>
                <div className="sm:col-span-4 mb-2">
                  <label className="block text-sm font-medium leading-6 text-white">Instansi</label>
                  <select disabled name="instansi" id="instansi" className="mt-2 block w-full rounded-sm py-1.5 px-2" onChange={(e) => handleChangeInputData({ instansi: e.target.value })} value={profileData?.instansi}>
                    <option value="" selected disabled>Pilih instansi anda</option>
                    <option value="Mabes TNI">Mabes TNI</option>
                    <option value="Kementerian Pertahanan">Kementerian Pertahanan</option>
                    <option value="Angkatan Darat">Angkatan Darat</option>
                    <option value="Angkatan Udara">Angkatan Udara</option>
                    <option value="Angkatan Laut">Angkatan Laut</option>
                  </select>
                </div>
                <div className="col-span-full mb-2">
                  <label className="block text-sm font-medium leading-6 text-white">Unit/Satuan Tugas</label>
                  <div className="mt-2">
                    <input
                      disabled
                      type="text"
                      name="unit"
                      id="unit"
                      value={profileData?.unit}
                      onChange={(e) => handleChangeInputData({ unit: e.target.value })}
                      className="block pl-2 w-full rounded-sm  py-1.5"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-50">
                <h5 className="font-bold text-white text-md">Foto Profil</h5>
                <div className="flex">
                  <div>
                    <div className="mt-2 ">
                      <div className=" h-36 w-36 text-gray-300 rounded-full border border-gray-900" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                        <img
                          src={profileData?.jalur_avatar == null ? '/assets/profile_default.png' : `${process.env.REACT_APP_MINIO_BE_URL}${profileData?.jalur_avatar}`}
                          className="w-full h-full rounded-full ring-2 ring-gray-300 dark:ring-gray-500 object-contain"
                          alt="logo-ad"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-white ml-8">Anda dapat mengunggah file gambar dengan format .jpg atau .png, gambar sebaiknya memiliki rasio yang sama misalnya dengan dimensi 300 x 300 piksel.</p>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      className="hidden mt-3 ml-9 rounded-md  bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-primary-lightGray"
                      onChange={(e) => handleChangeAvatar(e)}
                      id="imageInput"
                    />
                    <label htmlFor="imageInput" className="mt-3 ml-9 rounded-sm bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-primary-lightGray cursor-pointer">
                      Change
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="">
              <button type="submit" className="rounded-md bg-primary-lightBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-green">Save</button>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
}
