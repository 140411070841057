export const parseApprovalStatus = (status) => {
  const statuses = [
    "MENUNGGU",
    "SELESAI",
    "DITOLAK",
    "DISETUJUI",
    "DIBATALKAN",
  ];

  return statuses.indexOf(status);
};

export const parseUrgency = (status) => {
  const statuses = ["RENDAH", "SEDANG", "TINGGI"];

  return statuses.indexOf(status);
};

export const approvalStatusNotes = (status, { isUser, isOperator, isKabid }) => {
  const statusIndex = parseApprovalStatus(status);
  if (isUser) {
    return {
      0: "Pengajuan Anda sedang dalam proses persetujuan.",
      1: "Pengajuan Anda sudah diterima dan Anda dapat mengunduh citra yang Anda ajukan.",
      2: "Pengajuan Anda telah ditolak.",
      3: "Pengajuan Anda telah disetujui dan dalam proses penerimaan citra.",
      4: "Pengajuan Anda telah dibatalkan untuk proses lebih lanjut.",
    }[statusIndex];
  }

  if (isOperator) {
    return {
      0: "Pengajuan menunggu persetujuan dari Kabid.",
      1: "Pengajuan telah selesai.",
      2: "Pengajuan telah ditolak.",
      3: "Pengajuan citra perlu diproses lebih lanjut oleh Anda.",
      4: "Pengajuan telah dibatalkan untuk proses lebih lanjut.",
    }[statusIndex];
  }

  if (isKabid) {
    return {
      0: "Pengajuan menunggu persetujuan Anda.",
      1: "Pengajuan telah selesai.",
      2: "Pengajuan telah ditolak.",
      3: "Pengajuan citra perlu diproses lebih lanjut oleh Operator.",
      4: "Pengajuan telah dibatalkan untuk proses lebih lanjut.",
    }[statusIndex];
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
