import { useState } from "react";
import CustomModal from "../Views/Components/CustomModal/CustomModal";

const useModal = (options) => {
    const [isModalOpen, setIsmodalOpen] = useState(false);

    function openModal() {
        setIsmodalOpen(true);
    }

    function closeModal() {
        setIsmodalOpen(false);
    }

    const Modal = ({ children }) => {
        return (
            <CustomModal
                modalState={isModalOpen}
                closeModal={closeModal}
                title={options?.title}
            >
                {children}
            </CustomModal>
        )
    }

    return [openModal, Modal];
}

export default useModal;