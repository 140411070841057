import { useKeycloak } from "@react-keycloak/web";

const useKeycloakRoles = () => {
  const { keycloak } = useKeycloak();

  const isOperator = () => {
    return keycloak.hasResourceRole('operator');
  }

  const isUser = () => {
    return keycloak.hasResourceRole('user');
  }

  const isKabid = () => {
    return keycloak.hasResourceRole('kabid');
  }

  return [isOperator(), isUser(), isKabid()];
}

export default useKeycloakRoles; 