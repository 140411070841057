import { useState } from "react";
import Modal from 'react-modal';
import { FaExclamationTriangle } from "react-icons/fa";
import { IoInformationCircleSharp } from "react-icons/io5";

const useConfirmationModal = (options) => {
    const [isModalOpen, setIsmodalOpen] = useState(false);
    const [confirmFunction, setConfirmFunction] = useState(null);

    function openModal() {
        setIsmodalOpen(true);
    }

    function closeModal() {
        setIsmodalOpen(false);

        setConfirmFunction(null);
    }

    function handleModalConfirm(callback) {
        openModal();

        setConfirmFunction({ call: callback });
    }

    const ConfirmationModal = () => {
        return (
            <div>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    className="absolute w-[30rem] h-[50%] top-[25%] left-0 right-0 mr-auto ml-auto bg-primary-darkBlue text-white p-4 rounded-lg"
                    appElement={document.getElementById('root') || undefined}
                >
                    <div className="flex flex-column items-center justify-center h-full">
                        <div className="flex flex-column items-center mb-2">
                            <span className="text-7xl text-white mb-4">
                                <IoInformationCircleSharp />
                            </span>
                            <h3 className="mb-4">Sebelum Anda Melanjutkan</h3>
                            <p className="text-center m-0 mb-4">Tindakan ini tidak dapat dibatalkan. Apakah anda yakin ingin melanjutkan?</p>
                        </div>
                        <div className="flex">
                            <button
                                className="w-[50%] flex justify-center items-center bg-primary-green text-2xl hover:bg-hover-green transition duration-300 rounded-sm py-2 px-3"
                                onClick={async () => {
                                    try {
                                        await confirmFunction?.call();
                                    } catch (error) {
                                        console.error(error);
                                    }

                                    closeModal();
                                }}
                            >
                                Ya
                            </button>
                            <button
                                className="ml-3 w-[50%] flex justify-center items-center bg-primary-gray text-2xl hover:bg-hover-gray transition duration-300 rounded-sm py-2 px-3"
                                onClick={closeModal}
                            >
                                Tidak
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    return [handleModalConfirm, ConfirmationModal];
}

export default useConfirmationModal;