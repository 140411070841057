import React, { useEffect } from "react";
import { useState } from "react";
import logo from "./assets/blacksky_logo.png"
import SendDetail from "../Pages/MainMap/Components/SendDetail";
import { FaAngleLeft, FaAngleRight, FaUsers, FaSignOutAlt, FaHistory } from "react-icons/fa";
import { FaTableList } from "react-icons/fa6";
import { IoIosSend, IoMdContact } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdEditDocument } from "react-icons/md";
import { useKeycloak } from "@react-keycloak/web";
import useKeycloakRoles from "../../Hooks/useKeycloakRoles";
import { useUserContext } from "../../Context/UserContext";

const Sidebar = ({ map, isMarker, isShape, toogleShape, toogleMarker, polygonCoordinates, setPolygonCoordinates, markerCoordinates, setMarkerCoordinates }) => {
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [showSubmenuWithIndex, setShowSubmenuWithIndex] = useState(-1);
  const userContextData = useUserContext();

  const { keycloak } = useKeycloak();
  const [isOperator, isUser] = useKeycloakRoles();
  const location = useLocation();
  const navigate = useNavigate();

  const handleChangeSubmenuIndex = (idx) => {
    if (showSubmenuWithIndex === idx) {
      setShowSubmenuWithIndex(-1);
    } else {
      setShowSubmenuWithIndex(idx);
    }
  }

  useEffect(() => {
    setShowSubmenuWithIndex(-1);
  }, [location])

  const buttonStyle = "cursor-pointer flex justify-center items-center w-full h-[50px]";

  const ProfileSubmenu = () => {
    return (
      <div className="absolute right-[4rem] rounded-md bg-primary-blue text-white p-4 min-w-[25rem] min-h-[3rem]">
        <div className="flex items-center mb-4">
          <div className="w-1/4">
            <div className="w-[75px] h-[75px]">
              <img
                src={userContextData?.jalur_avatar == null ? '/assets/profile_default.png' : `${process.env.REACT_APP_MINIO_BE_URL}${userContextData?.jalur_avatar}`}
                className="w-full h-full rounded-full ring-2 ring-gray-300 dark:ring-gray-500 object-contain"
                alt="logo-ad"
              />
            </div>
          </div>
          <div className="ml-2 pl-2 border-l-4 border-primary-green">
            <p className="m-0 text-lg font-bold">{userContextData?.unit}</p>
            <p className="m-0">{userContextData?.instansi}</p>
          </div>
        </div>
        <div className="flex items-center mb-3 pb-3 border-b-2">
          <IoMdContact size={20} />
          <p onClick={() => navigate("/profil")} className="m-0 pl-2 hover:cursor-pointer hover:underline">Profil Saya</p>
        </div>
        <div className="flex items-center">
          <FaSignOutAlt size={20} />
          <p className="m-0 pl-2 hover:cursor-pointer hover:underline" onClick={() => keycloak.logout()} >Keluar</p>
        </div>
      </div>
    )
  }

  const MapMenus = () => {
    return (
      <div className={`${buttonStyle} bg-primary-purple hover:bg-hover-purple transition duration-300`} onClick={() => setIsShowDetail(!isShowDetail)}>
        {isShowDetail ?
          <FaAngleRight />
          :
          <FaAngleLeft />
        }
      </div>
    )
  }

  return (
    <>
      <div className="fixed z-50 bg-primary-blue text-white right-0 w-[54px] h-full">
        <div className="flex justify-center text-3xl">
          {location.pathname.includes('map') && isUser &&
            <MapMenus />
          }
        </div>
        <img src={logo} alt="blackskylogo" />
        {isUser &&
          <Link to="/map" className={`${buttonStyle} text-white text-3xl hover:bg-hover-blue transition duration-300 ${location.pathname === '/map' ? 'bg-primary-darkBlue' : ''}`} title="Pengajuan">
            <IoIosSend />
          </Link>
        }
        <Link to="/pengajuan" className={`${buttonStyle} text-white text-3xl hover:bg-hover-blue transition duration-300 ${location.pathname === '/pengajuan' ? 'bg-primary-darkBlue' : ''}`} title="Daftar pengajuan aktif">
          <FaTableList />
        </Link>
        <Link to="/riwayat" className={`${buttonStyle} text-white text-3xl hover:bg-hover-blue transition duration-300 ${location.pathname === '/riwayat' ? 'bg-primary-darkBlue' : ''}`} title="Riwayat pengajuan">
          <FaHistory />
        </Link>
        {/* {isOperator &&
          <Link to="/daftar-user" className={`${buttonStyle} text-white text-3xl hover:bg-hover-blue transition duration-300 ${location.pathname === '/daftar-user' ? 'bg-primary-darkBlue' : ''}`} title="Daftar user">
            <FaUsers />
          </Link>
        } */}
        <div className="relative">
          {showSubmenuWithIndex === 0 &&
            <ProfileSubmenu />
          }
          <div className={`${buttonStyle} text-white text-3xl hover:bg-hover-blue transition duration-300 relative ${location.pathname === '/profil' ? 'bg-primary-darkBlue' : ''}`} title="Akun" onClick={() => handleChangeSubmenuIndex(0)}>
            <IoMdContact />
          </div>
        </div>
      </div>

      {/* SUBCOMPONENT */}
      <div className={`fixed overflow-auto z-40 bg-primary-darkPurple text-white top-0 right-0 w-96 h-full px-4 py-3 transition transform duration-300 ${isShowDetail ? '-translate-x-[54px]' : 'translate-x-full'}`}>
        <SendDetail map={map} isMarker={isMarker} isShape={isShape} isShowDetail={isShowDetail} toogleShape={toogleShape} toogleMarker={toogleMarker} polygonCoordinates={polygonCoordinates} setPolygonCoordinates={setPolygonCoordinates} markerCoordinates={markerCoordinates} setMarkerCoordinates={setMarkerCoordinates} />
      </div>
    </>
  )
}

export default Sidebar;